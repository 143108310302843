import { RadiologistStudyKeyImagesResponse } from '@/api';

export function mapKeyImages(keyImagesData: RadiologistStudyKeyImagesResponse) {
  return keyImagesData.key_images.instanceNumbers.map((instanceNumber) => ({
    id: keyImagesData.key_images.imageKeys[instanceNumber],
    image: keyImagesData.images[instanceNumber],
    instanceNumber,
    processedS3Key: keyImagesData.key_images.processed[instanceNumber],
  }));
}
