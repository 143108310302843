import { useMutation, useQuery, UseQueryOptions } from '@tanstack/react-query';

import { ENDPOINTS } from '@/api/endpoints';
import { fetcher } from '@/api/fetcher';
import {
  PatchReportOrganFromUpdatedFindingsError,
  PatchReportOrganFromUpdatedFindingsRequest,
  PatchReportOrganFromUpdatedFindingsResponse,
  ReportOrgansByOrganError,
  ReportOrgansByOrganRequest,
  ReportOrgansByOrganResponse,
  ReportOrgansError,
  ReportOrgansRequest,
  ReportOrgansResponse,
} from '@/api/model/fct/report-organ';

const KEY = {
  GET_REPORT_ORGAN_BY_ORGAN: 'GET_REPORT_ORGAN_BY_ORGAN',
  GET_REPORT_ORGANS: 'GET_REPORT_ORGANS',
} as const;

const request = {
  getReportOrganByOrgan: async (
    { payload: { organ, reportUuid } }: ReportOrgansByOrganRequest,
    signal?: AbortSignal,
  ) => {
    const { data } = await fetcher.FCT.get<ReportOrgansByOrganResponse>(
      `${ENDPOINTS.FCT.REPORT_ORGAN.REPORT}${reportUuid}/organ/${organ}`,
      { signal },
    );

    return data;
  },
  getReportOrgans: async (
    { payload: { reportUuid } }: ReportOrgansRequest,
    signal?: AbortSignal,
  ) => {
    const { data } = await fetcher.FCT.get<ReportOrgansResponse>(
      `${ENDPOINTS.FCT.REPORT_ORGAN.REPORT}${reportUuid}`,
      { signal },
    );

    return data;
  },
  patchReportOrganFromUpdatedFindings: async ({
    payload,
  }: PatchReportOrganFromUpdatedFindingsRequest) => {
    const { data } = await fetcher.FCT.patch<PatchReportOrganFromUpdatedFindingsResponse>(
      `${ENDPOINTS.FCT.REPORT_ORGAN.FROM_UPDATED_FINDINGS}`,
      payload,
    );

    return data;
  },
};

const query = {
  useGetReportOrganByOrgan: (
    { payload }: ReportOrgansByOrganRequest,
    config?: UseQueryOptions<ReportOrgansByOrganResponse, ReportOrgansByOrganError>,
  ) =>
    useQuery<ReportOrgansByOrganResponse, ReportOrgansByOrganError>({
      queryFn: ({ signal }) => request.getReportOrganByOrgan({ payload }, signal),
      queryKey: [KEY.GET_REPORT_ORGAN_BY_ORGAN, payload.reportUuid, payload.organ],
      ...config,
    }),
  useGetReportOrgans: (
    { payload }: ReportOrgansRequest,
    config?: UseQueryOptions<ReportOrgansResponse, ReportOrgansError>,
  ) =>
    useQuery<ReportOrgansResponse, ReportOrgansError>({
      queryFn: ({ signal }) => request.getReportOrgans({ payload }, signal),
      queryKey: [KEY.GET_REPORT_ORGANS, payload.reportUuid],
      ...config,
    }),
};

const mutation = {
  usePatchReportOrganFromUpdatedFindings: () =>
    useMutation<
      PatchReportOrganFromUpdatedFindingsResponse,
      PatchReportOrganFromUpdatedFindingsError,
      PatchReportOrganFromUpdatedFindingsRequest
    >({
      mutationFn: request.patchReportOrganFromUpdatedFindings,
    }),
};

export const reportOrgan = {
  KEY,
  mutation,
  query,
  request,
};
