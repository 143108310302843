import { ReactNode, useMemo } from 'react';

import { RadiologistScanDetail, User } from '@/api';

import { StudyGuardContext } from './study-guard.context';
import { StudyGuardState } from './study-guard.model';

export interface StudyGuardProviderProps {
  children: ReactNode;
  patient: User;
  radiologistScan: RadiologistScanDetail;
  studyId: string;
}

export function StudyGuardProvider({
  children,
  patient,
  radiologistScan,
  studyId,
}: StudyGuardProviderProps) {
  const value: StudyGuardState = useMemo(
    () => ({ patient, radiologistScan, studyId }),
    [radiologistScan, studyId, patient],
  );

  return <StudyGuardContext.Provider value={value}>{children}</StudyGuardContext.Provider>;
}
