import { Button, Icon, Stack, Typography } from '@prenuvo/halo-foundation';
import { WarningTriangle } from '@prenuvo/halo-icon';

import { FCT } from '@/i18n';

import { CONFIG } from '../config';

export function InvalidRoles() {
  const handleReturnToLogin = () => {
    window.location.replace(`${CONFIG.APPS_SERVER}/login?redirectUrl=${window.location.href}`);
  };

  return (
    <Stack alignment="center" className="mx-auto h-screen" justification="center">
      <Stack
        className="m-8 rounded-xl border border-orange-700 p-16 text-center"
        gap={8}
        variant="vertical"
      >
        <Stack alignment="center" variant="vertical">
          <Icon className="fill-orange-700" size="lg" source={WarningTriangle} />
          <Typography variant="h2">{FCT.ROLES.INVALID.ALERT.TITLE}</Typography>
        </Stack>

        <Stack alignment="center" gap={4} variant="vertical">
          <Typography className="italic dark:text-neutral-500">
            {FCT.ROLES.INVALID.ACTIONS.MESSAGE}
          </Typography>

          <Button onClick={handleReturnToLogin} size="lg">
            {FCT.ROLES.INVALID.ACTIONS.LOGIN}
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
}
