export const transformSnakeCaseToPascalCase = (str: string) =>
  str
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');

export const transformSnakeCaseToKebabCase = (str: string) => str.split('_').join('-');

export const transformSentenceToKebabCase = (str: string) =>
  str
    .trim()
    .toLowerCase()
    .replace(/[\s,.&]+/g, '-')
    .replace(/[^a-z0-9-]/g, '');
