import { StudyResult } from './use-patient-studies.type';

type StudyArg = {
  booking: Array<{
    skuIDs: string[];
    utcStart: number;
  }>;
  studyID: string;
};

export const getPreviousStudyIds = (
  studies: StudyArg[],
  currentStudyId: string | undefined,
  scanDate: number,
  limit = 8,
): StudyResult[] => {
  if (!studies.length) {
    return [];
  }

  const previousStudies = studies.filter(
    (study) => study.studyID !== currentStudyId && study.booking[0].utcStart < scanDate,
  );

  if (!previousStudies.length) {
    return [];
  }

  return previousStudies
    .sort((a, b) => b.booking[0].utcStart - a.booking[0].utcStart)
    .slice(0, limit)
    .map((study) => ({
      id: study.studyID,
      date: study.booking[0].utcStart,
      skuIDs: study.booking[0].skuIDs,
    }));
};
