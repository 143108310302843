import { generatePath } from 'react-router-dom';

import { ValueOf } from './util.model';

const REPORTING_ROUTES = {
  FINAL_IMPRESSIONS: 'final-impressions',
  KEY_IMAGES: 'key-images',
  ORGAN: 'organ/:organ',
  PREVIEW: 'preview',
  REASON_FOR_SCAN: 'reason-for-scan',
  REPORTING: '/reporting/:studyId',
  RETRIEVE_KEY_IMAGES: 'retrieve-key-images',
} as const;

const ACTIVITY_LOGS_ROUTES = {
  ACTIVITY_LOGS: '/activity-logs/:studyId',
} as const;

export const APP_ROUTES = {
  NO_ACCESS: '/401',
  NOT_FOUND: '/404',
  ...REPORTING_ROUTES,
  ...ACTIVITY_LOGS_ROUTES,
} as const;

export type AppRouteKey = keyof typeof APP_ROUTES;

export type AppRoute = ValueOf<typeof APP_ROUTES>;

export type RoutePathParam = Parameters<typeof generatePath<AppRoute>>[1];

export type ParentRoute = 'REPORTING';

export interface RouteConfig {
  parent?: ParentRoute;
}

export type PathConfig = {
  params?: Partial<RoutePathParam>;
  query?: string;
};

export const DEFAULT_PATH_PARAMS: NonNullable<RoutePathParam> = {
  organ: null,
  studyId: null,
};

export const ROUTES: { [key in AppRouteKey]: RouteConfig } = {
  ACTIVITY_LOGS: {},
  FINAL_IMPRESSIONS: {
    parent: 'REPORTING',
  },
  KEY_IMAGES: {
    parent: 'REPORTING',
  },
  NO_ACCESS: {},
  NOT_FOUND: {},
  ORGAN: { parent: 'REPORTING' },
  PREVIEW: {
    parent: 'REPORTING',
  },
  REASON_FOR_SCAN: {
    parent: 'REPORTING',
  },
  REPORTING: {},
  RETRIEVE_KEY_IMAGES: {
    parent: 'REPORTING',
  },
};
