export const FCT = {
  ACTIVITY_LOGS: {
    TIMESTAMP_LABEL: {
      LAST: 'Last',
      TODAY: 'Today',
      YESTERDAY: 'Yesterday',
    },
    USER_ENTRY_MESSAGE: 'User reporting session started',
  },
  NOT_FOUND: {
    ACTIONS: {
      BACK: 'Go back to dashboard',
    },
    DESCRIPTION: 'We are sorry, the page you are trying to access could not be found.',
    TITLE: 'Error 404: Page not found',
  },
  REPORTING: {
    ENTRY: {
      FINAL_IMPRESSIONS: {
        COMPARISON_REPORT: {
          CHANGED_FROM_PREVIOUS: 'Changed from previous',
          DATE_OF_PREVIOUS: 'Date of previous',
          NO_LABEL: 'No',
          NO_PREVIOUS_AVAILABLE: 'No previous available',
          OR_LABEL: 'Or',
          VALID_DATE_LABEL: 'Please enter a valid date',
          YES_LABEL: 'Yes',
        },
        EDITOR: {
          PLACEHOLDER: 'Type your final impressions here',
        },
        FINALIZATION: {
          ACKNOWLEDGEMENT: {
            ACTIONS: {
              ACKNOWLEDGE: 'Acknowledge',
              ACKNOWLEDGED: 'Acknowledged',
            },
            CONFIRM: 'I confirm the above is accurate.',
          },
          DEFAULT_QUESTIONS: {
            TITLE: 'Default questions',
          },
          LOADING: 'Checking Findings for high scores and skipped organs...',
          NORMALS: {
            LABEL: 'Normals accordion',
            STATEMENTS: 'Normal Statements',
          },
          ORGAN: {
            JUMP_TO: 'Jump To',
            JUMP_TO_ORGAN: 'Jump to organ',
            WARNING: 'warning',
          },
          PANEL: {
            TITLE:
              'If you have entered all your findings for this study, please acknowledge the following ONCO and CSD scores:',
          },
        },
        FOLLOW_UP: 'Follow up for next scan in',
        MONTHS: 'months',
        NOT_APPLICABLE: 'Not Applicable',
        TITLE: 'Final impressions',
      },
      KEY_IMAGES: {
        GALLERY: {
          EMPTY: {
            DESCRIPTION: {
              LINK: 'here.',
              PRIMARY: 'No key images to show. Key images can be retrieved',
              SECONDARY: 'Please ensure the study is closed and saved on PACS before retrieving.',
            },
          },
          ERROR: {
            DESCRIPTION: 'Select a finding label or exclude',
          },
          STATUS: {
            ERROR: {
              DESCRIPTION:
                'The number of labels needs to equal the number of key images. Please correct accordingly.',
              TITLE: (number: number) => `including ${number} unresolved`,
            },
            TOTAL: 'Total',
          },
        },
        MAPPING: {
          DESCRIPTION: 'Please make sure all key images are correctly mapped to their labels.',
          HEADING: 'Mapping',
        },
        TABLE: {
          ACTION: {
            ADD_ANOTHER_LABEL: 'Add another label',
            EDIT_LABEL: 'Edit label',
            REMOVE_LABEL: 'Remove label',
          },
          HEADER: {
            FINDING: 'Finding',
            LABEL: 'Label',
          },
          NO_LABEL_ASSIGNED: 'No label assigned',
        },
        TITLE: 'Key Images',
      },
      KEY_IMAGES_RETRIEVE: {
        ACTIONS: {
          RETRIEVE: 'Retrieve Key Images',
        },
        DESCRIPTION: {
          PRIMARY:
            'To ensure key images are up to date, please first close the study on PACS. Then click the button below to retrieve the key images.',
          SECONDARY: 'After retrieval, they will be available on the Key Images page.',
        },
        ERROR:
          'Failed to retrieve key images. Please ensure study is closed and saved on PACS, then try again in a minute.',
        LOADING:
          'Retrieving. Continue to the next section, and any key images retrieved will be available on the Key Images page.',
        TITLE: 'Retrieve key images',
      },
      ORGAN: {
        ACCORDION: {
          CONDITION: {
            TITLE: 'Condition',
            TRIGGER: {
              SUBTITLE: 'Select a template from the list',
              TITLE: 'Findings',
            },
          },
        },
        ACTIONS: {
          DONE: 'Done, next',
        },
        FINDING: {
          AI_ACKNOWLEDGED: {
            LABEL: 'AI, acknowledge',
            TOOLTIP: {
              CONTENT: 'Confirm AI-populated values (highlighted in green) are correct',
            },
          },
          DELETE_FINDING: {
            ACTIONS: {
              DELETE: 'Delete Finding',
            },
          },
          FINDING_PARAMETERS: {
            OBSERVATION: {
              ACTIONS: {
                ADD: 'Add Observation',
                DELETE: 'Delete',
              },
              TITLE: 'Observation',
            },
          },
          SPONDY_CONDITIONS: {
            ACTIONS: {
              DELETE: 'Delete Finding',
            },
            LABELS: {
              CLINICAL_NOTES_PLACEHOLDER: 'Please give us as much information as you can',
              INPUT_PLACEHOLDER: 'Enter finding here (if applicable)',
              SPONDYLOARTHROPATHY_OF_THE_CERVICAL_SPINE: 'Cervical region',
              SPONDYLOARTHROPATHY_OF_THE_LUMBAR_SPINE: 'Lumbar region',
              SPONDYLOARTHROPATHY_OF_THE_THORACIC_SPINE: 'Thoracic region',
            },
            OUTPUT_LABELS: {
              SPONDYLOARTHROPATHY_OF_THE_CERVICAL_SPINE: 'Spondyloarthropathy - Cervical',
              SPONDYLOARTHROPATHY_OF_THE_LUMBAR_SPINE: 'Spondyloarthropathy - Lumbar',
              SPONDYLOARTHROPATHY_OF_THE_THORACIC_SPINE: 'Spondyloarthropathy - Thoracic',
            },
            TITLE: 'Spondyloarthropathy',
          },
        },
        FINDING_OUTPUT: {
          DOT_INDICATOR: {
            DOT: 'Dot',
            OF: 'of',
          },
          KEY_IMAGE_FINDING_LABEL_INPUT: {
            ACTIONS: {
              ADD: 'Add key image label',
              CLEAR: 'Clear key image label',
              REMOVE: 'Remove key image label',
            },
            ERRORS: {
              VALID: 'Please enter valid key image label',
            },
            LABEL: 'Key image label',
          },
          TOGGLE_GROUP: {
            EXISTING_FINDING: 'Existing Finding',
            NEW_FINDING: 'New Finding',
          },
          WARNING: {
            DESCRIPTION: 'We need more information in order to form an output',
          },
        },
        GROUPED_FINDING_OUTPUT: {
          EMPTY: {
            ACTIONS: {
              ADD: 'Add key image label',
            },
            DESCRIPTION: 'We detected evidence of spondyloarthropathy.',
            WARNING: {
              DESCRIPTION: 'We need more information in order to form an output',
            },
          },
        },
        NO_FINDINGS_SELECTED: 'No findings selected',
        NORMAL_STATEMENTS: {
          TITLE: 'Normal Statements',
        },
        ON_DEMAND_CONDITION: {
          ANKLES_VISIBLE: {
            TITLE: 'Are the ankles visible?',
          },
        },
        ORGAN_OUTPUT: {
          TITLE: 'Organ Output',
        },
        TOOLTIP: {
          ACTIONS: {
            OTHER: 'Other',
          },
          CONTENT: 'Please fill in the details for already added findings',
        },
      },
      REASON_FOR_SCAN: {
        MEDICAL_HISTORY: {
          HEADER: 'General History',
        },
        REASON_FOR_SCAN_DISPLAY: {
          PATIENT: {
            SECTION_WITH_NOTES: {
              PATIENT_NOTE: 'Patient note:',
              PRIMARY_REASON_FOR_SCAN: 'Primary reason for a scan:',
              SECONDARY_REASON_FOR_SCAN: 'Secondary reason for a scan:',
            },
            TITLE: 'Patient',
          },
          SAFETY_QUESTIONS: {
            ACCORDION: {
              LABEL: 'safety question accordion',
            },
            TITLE: 'Safety Questions',
          },
          SECTION_WITH_NOTES: {
            NOT_PROVIDED: 'Not Provided',
          },
          TECHNOLOGISTS_NOTES: {
            TITLE: 'Technologist notes:',
          },
        },
        TITLE: 'Reason for a scan and notes',
      },
    },
    HEADER: {
      ACTIONS: {
        COPY_STUDY_DETAILS: 'Copy study details',
        MEDICAL_HISTORY: 'Medical History',
        NAVIGATE_BACK: 'Navigate back',
        PREVIOUS_REPORT: 'Previous Report',
        PT_HX: 'Pt Hx',
        RESET_REPORT: 'Reset Report',
      },
      DOB: (dob: string = 'Unknown DoB', age: number | string = 'Unknown Age') =>
        `${dob} - ~Age: ${age}`,
      GENDER: {
        FEMALE: 'Female',
        MALE: 'Male',
      },
      RESET_REPORT: {
        DESCRIPTION:
          'Please confirm to reset the report to the initial state. The screen will refresh after the reset.',
      },
      UNKNOWN: {
        DATE: 'Unknown Date',
        GENDER: 'Unknown Gender',
        NAME: 'Unknown Patient Name',
        SCANNER: 'Unknown Scanner',
      },
    },
    PREVIEW: {
      ACTIONS: {
        SUBMIT: 'Submit This Report',
      },
      CLINICAL_IMAGES: {
        FIGURE: 'Figure',
        TITLE: 'CLINICAL IMAGES',
      },
      COMPARISON_REPORT: {
        CHANGED_FROM_PREVIOUS:
          'The following was identified, and there have been changes since your previous study on',
        NO_PREVIOUS_REPORT: 'You have not had a previous Prenuvo scan.',
        TITLE: 'COMPARISON',
        UNAVAILABLE: 'Comparison with previous scan is unavailable.',
        UNCHANGED_FROM_PREVIOUS:
          'The following was identified, and is generally unchanged from your previous study on',
      },
      DISCUSSION: {
        MESSAGE_DEFAULT:
          'The Prenuvo whole-body MRI screening: ' +
          '(1) can serve as an adjunct to, but is not intended to replace, other established evidence-based screening practices for early detection of specific malignancies (e.g. colonoscopy, dedicated breast imaging, Pap-smear screening for cervical cancer, low-dose chest CT for high risk patients), ' +
          '(2) is effective for visualization of solid lesions on the order of 1 cm or larger within the head, neck, chest, abdomen, and pelvis. As with any medical test, there are limitations, which make it impossible to detect all malignancies and disease conditions, ' +
          '(3) is generally sensitive and specific for detection of cerebral artery aneurysms on the order of 3 mm or greater in size, ' +
          '(4) does not evaluate the heart or heart vessels, ' +
          '(5) does not evaluate lung microarchitecture or pulmonary micronodules, ' +
          '(6) does not replace dedicated breast imaging for screening or diagnostic evaluation (e.g. mammography, breast ultrasound, breast MRI with contrast), ' +
          '(7) is limited in the evaluation of the gastrointestinal tract and does not replace endoscopy or colonoscopy (e.g. cannot detect bowel polyps), ' +
          '(8) is limited in its assessment of the large joints as the exam is not tailored for detailed evaluation of the joint ligaments, cartilage, menisci, and labrum, ' +
          '(9) should not be considered a primary screening modality of the skin. This is best assessed by direct physical examination, ' +
          '(10) is not intended to replace dedicated diagnostic imaging in the setting of specific clinical diagnostic questions.',
        MESSAGE_TORSO:
          'The Prenuvo whole-body MRI screening: ' +
          '(1) Can serve as an adjunct to, but is not intended to replace, other established evidence-based screening practices for early detection of specific malignancies (e.g. colonoscopy, dedicated breast imaging, Pap-smear screening for cervical cancer, low-dose chest CT for high risk patients), ' +
          '(2) is effective for visualization of solid lesions on the order of 1.5 cm or larger within the chest, abdomen, and pelvis. As with any medical test, there are limitations, which make it impossible to detect all malignancies and disease conditions, ' +
          '(3) does not evaluate the heart or heart vessels, ' +
          '(4) does not evaluate lung microarchitecture or pulmonary micronodules, ' +
          '(5) does not replace dedicated breast imaging for screening or diagnostic evaluation (e.g. mammography, breast ultrasound, breast MRI with contrast), ' +
          '(6) is limited in the evaluation of the gastrointestinal tract and does not replace endoscopy or colonoscopy (e.g. cannot detect bowel polyps), ' +
          '(7) is limited in its assessment of the large joints as the exam is not tailored for detailed evaluation of the joint ligaments, cartilage, menisci, and labrum, ' +
          '(8) should not be considered a primary screening modality of the skin. This is best assessed by direct physical examination, ' +
          '(9) is not intended to replace dedicated diagnostic imaging in the setting of specific clinical diagnostic questions.',
        TITLE: 'DISCUSSION',
      },
      FINAL_IMPRESSIONS: 'FINAL IMPRESSIONS',
      FINDINGS: 'FINDINGS',
      LABEL: {
        SENSITIVE: 'Sensitive',
        SIGNATURE: 'Add second signature',
        URGENT: 'Urgent',
      },
      NEXT_APPOINTMENT: 'NEXT APPOINTMENT',
      NEXT_RECOMMENDED_SCAN: {
        NONE: 'No follow up scan is suggested',
        SUGGESTED: (count?: number) => `A follow up scan in ${count} months is suggested for further
                  proactive health unless clinically indicated sooner.`,
      },
      REPORT_RECIPIENT: {
        NOT_APPLICABLE: 'N/A',
        TITLE: 'REPORT RECIPIENT(S)',
      },
      SECOND_SIGNATURE: {
        DESCRIPTION:
          'Requiring a second signature will assign this study to another selected radiologist once published.',
        NO_RADIOLOGIST: {
          INFO: 'Radiologist will receive the study in their dashboard',
          PLACEHOLDER: 'Select Radiologist',
        },
        TITLE: 'Second signature:',
      },
      SECTION: {
        FINDING: {
          FIGURE: 'Figure',
          SEE_LABEL: 'See',
        },
        ORGAN: {
          ASSESSED: 'Assessed',
          DISABLED_ORGAN: (organ: string, sku: string) =>
            `The ${organ} ${organ.endsWith('s') ? 'are' : 'is'} not assessed in the ${sku} scan.`,
          FINDINGS: 'Findings',
        },
      },
      SIGNATURE: {
        DESCRIPTION: 'Please sign the field below',
        TITLE: 'Signature',
      },
    },
    PUBLISH_DIALOG: {
      ACTIONS: {
        RETRY: 'Retry Publish',
      },
      MESSAGE: {
        AUTO_CLOSE: 'The window will close automatically in a few seconds...',
        GENERATING_PDF: 'Generating PDF. Please wait...',
        PUBLISHING: 'Publishing your report. Please wait...',
        SUCCESS: 'Report successfully published!',
      },
      TITLE: {
        COMPLETE: 'Publication Complete',
        ERROR: 'Publication Error',
        PUBLISHING: 'Publishing Report',
      },
    },
    REPORT_ISSUE: {
      ACTIONS: {
        CANCEL: 'Cancel',
        CLOSE: 'Close',
        REMOVE_FILE: 'Remove file',
        REPORT_ISSUE: 'Report issue',
        SUBMIT: 'Submit',
      },
      ERROR: {
        DESCRIPTION: 'An error occurred while submitting your report. Please try again.',
        TITLE: 'Error',
      },
      ISSUE_TYPE: {
        REPORT_ISSUE: {
          DESCRIBE:
            'Describe actions taken leading up to the issue such as inputs, button clicks, errors, and observations.',
          EXPLAIN: 'Explain the observed issue, and why it is an issue.',
          SHARE: 'Share any steps you took to troubleshoot the issue before reporting it.',
          TITLE: 'Read what to include in your description',
        },
        SUGGEST_IMPROVEMENT: {
          DESCRIBE: 'Describe the correction or improvement you are suggesting.',
          IF: 'If any, share ideas for how the issue could be improved.',
          SHARE:
            'Share where and when you encountered the issue or felt the improvement was needed.',
          TITLE: 'Read what to include in your description',
        },
      },
      LABEL: {
        DESCRIPTION: 'Description',
        TITLE: 'Title',
        WHAT_IS_IT_ABOUT: 'What is it about?',
        WHAT_WOULD_YOU_LIKE_TO_DO: 'What would you like to do?',
      },
      SUCCESS: 'Thank you, your report has been submitted.',
      TITLE: 'Report issue',
    },
    TECHNIQUE: {
      EDITOR: {
        NOTES_PLACEHOLDER: 'Type your notes here',
        TECHNIQUE_PLACEHOLDER: 'Type your technique here',
      },
      NOT_PROVIDED: 'Not provided',
      NOTES: 'Add notes to technique',
      READ_ONLY_NOTES: 'Additional Technique Notes',
      READ_ONLY_TITLE: 'TECHNIQUE',
      TITLE: 'Technique',
    },
  },
  ROLES: {
    INVALID: {
      ACTIONS: {
        LOGIN: 'Return to login',
        MESSAGE: 'You are not authorized to access this page. Contact support team for assistance.',
      },
      ALERT: {
        TITLE: 'Unauthorized access',
      },
    },
  },
} as const;
