import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';

import { API_ENDPOINTS, makeApiRequest, RadiologistStudyMedicalHistoryResponse } from '@/api';
import { useMedicalHistoryStore } from '@/modules/reporting/shared/component/medical-history/use-medical-history.store';
import { OrganHistoryKey, ORGANS_HISTORY_MAP } from '@/shared/constant';
import { useStudyGuard } from '@/shared/guard';

export const useMedicalHistoryQuery = () => {
  const { studyId } = useStudyGuard();

  const { setMedicalHistory } = useMedicalHistoryStore();

  const fetchMedicalHistory = async () => {
    const { data }: RadiologistStudyMedicalHistoryResponse = await makeApiRequest(
      'HI',
      `${API_ENDPOINTS.hi.radStudy}${studyId}/medical-history`,
      'GET',
    );

    return data;
  };

  const { data: medicalHistory, isLoading } = useQuery({
    enabled: !!studyId,
    queryFn: fetchMedicalHistory,
    queryKey: ['generalMedicalHistory', studyId],
    staleTime: Infinity,
  });

  useEffect(() => {
    if (medicalHistory) {
      const updatedData = Object.keys(medicalHistory).reduce((acc, current) => {
        const updatedOrgan = ORGANS_HISTORY_MAP[current as OrganHistoryKey];

        if (updatedOrgan) {
          return {
            ...acc,
            [updatedOrgan]: medicalHistory[current],
          };
        }

        return {
          ...acc,
          [current]: medicalHistory[current],
        };
      }, {});

      setMedicalHistory(updatedData);
    }
  }, [medicalHistory, setMedicalHistory]);

  return { isLoading, medicalHistory };
};
