import { ELEMENT_IDENTIFIER } from '@/shared/constant';
import { useScrollPositionStore } from '@/store';

export function usePreviewScrollPosition() {
  const { setPosition } = useScrollPositionStore();

  return () => {
    const previewContainer = document.getElementById(ELEMENT_IDENTIFIER.PREVIEW);

    if (previewContainer) {
      setPosition('preview', previewContainer.scrollTop);
    }
  };
}
