import { OrganValidationStatus } from '@/store';

export const getStatusContent = (status: OrganValidationStatus | undefined) => {
  const result = ' - ';

  if (status?.error) {
    return `${result} Incomplete`;
  }

  if (status?.warning) {
    return `${result} ONCO/CSD 4+`;
  }

  return '';
};
