import { useParams } from 'react-router-dom';

import { useFormStructure } from '@/shared/guard';
import { OrganLink } from '@/shared/model';

function checkForValidOrganInLinks(links: OrganLink[], organKey: string) {
  return links.some(
    (item) => item.key === organKey || item.sub?.some((sub) => sub.key === organKey),
  );
}

export function useRouteValidation() {
  const { organ } = useParams<{ organ: string }>();

  const { links } = useFormStructure();

  return links && organ ? checkForValidOrganInLinks(links, organ.replace(/-/g, '_')) : true;
}
