import { createBrowserRouter, Navigate } from 'react-router-dom';

import { AuthGuard } from '@/core/auth';
import { ActivityLogsRoute } from '@/modules/activity-logs';
import { ErrorBoundaryRoute } from '@/modules/error-boundary';
import { NoAccessRoute } from '@/modules/no-access';
import { NotFoundRoute } from '@/modules/not-found';
import {
  FinalImpressionRoute,
  KeyImagesRetrieveRoute,
  KeyImagesRoute,
  OrganRoute,
  PreviewRoute,
  ReasonForScanRoute,
  ReportingLayout,
} from '@/modules/reporting';
import { FormStructureGuard, StudyGuard } from '@/shared/guard';

export const ROUTES = createBrowserRouter(
  [
    {
      children: [
        {
          element: <Navigate replace to="reason-for-scan" />,
          index: true,
        },
        {
          element: <ReasonForScanRoute />,
          path: 'reason-for-scan',
        },
        {
          element: <OrganRoute />,
          path: 'organ/:organ',
        },
        {
          element: <FinalImpressionRoute />,
          path: 'final-impressions',
        },
        {
          element: <KeyImagesRoute />,
          path: 'key-images',
        },
        {
          element: <KeyImagesRetrieveRoute />,
          path: 'retrieve-key-images',
        },
        {
          element: <PreviewRoute />,
          path: 'preview',
        },
      ],
      element: (
        <AuthGuard>
          <StudyGuard>
            <FormStructureGuard>
              <ReportingLayout />
            </FormStructureGuard>
          </StudyGuard>
        </AuthGuard>
      ),
      errorElement: <ErrorBoundaryRoute />,
      path: '/reporting/:studyId',
    },
    {
      element: (
        <AuthGuard>
          <StudyGuard>
            <ActivityLogsRoute />
          </StudyGuard>
        </AuthGuard>
      ),
      errorElement: <ErrorBoundaryRoute />,
      path: '/activity-logs/:studyId',
    },
    {
      element: <Navigate replace to="/404" />,
      path: '*',
    },
    {
      element: (
        <AuthGuard>
          <NotFoundRoute />
        </AuthGuard>
      ),
      errorElement: <ErrorBoundaryRoute />,
      path: '404',
    },
    {
      element: <NoAccessRoute />,
      path: '401',
    },
  ],
  {
    future: {
      v7_relativeSplatPath: true,
    },
  },
);
