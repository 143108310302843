import { forwardRef, memo, useEffect, useImperativeHandle, useRef, useState } from 'react';
import Quill from 'quill';

import './editor.css';

import { FORMATS } from './editor.constant';
import { convertTextToBulletPoints } from './editor.util';

export type EditorProps = {
  'aria-label'?: string;
  className?: string;
  formats?: string[];
  isReadOnly?: boolean;
  onBlur?: (editedText: string, currentText?: string) => void;
  onChange?: (value: string) => void;
  placeholder?: string;
  preventBlank?: boolean;
  value: string;
  variant?: 'outlined';
};

const EditorComponent = forwardRef(
  (
    {
      'aria-label': ariaLabel,
      className,
      formats = FORMATS,
      isReadOnly = false,
      onBlur,
      onChange,
      placeholder = '',
      preventBlank = false,
      value,
      variant,
      ...restProps
    }: EditorProps,
    ref,
  ) => {
    const editorRef = useRef<HTMLDivElement | null>(null);
    const quillRef = useRef<null | Quill>(null);
    const [initialValue, setInitialValue] = useState(value);
    const formattedValue = convertTextToBulletPoints(value);

    useImperativeHandle(ref, () => ({
      blur: () => {
        quillRef.current?.blur();
      },
    }));

    useEffect(() => {
      if (editorRef.current) {
        if (!quillRef.current) {
          quillRef.current = new Quill(editorRef.current, {
            bounds: editorRef.current,
            formats,
            modules: {
              keyboard: {
                bindings: {
                  tab: {
                    key: 9,
                    handler: () => true,
                  },
                },
              },
              toolbar: [
                ['bold', 'italic', 'underline'],
                [{ align: ['right', 'center', 'justify'] }],
                [{ list: 'bullet' }],
              ],
            },
            placeholder,
            readOnly: isReadOnly,
            theme: 'bubble',
          });

          const html = quillRef.current.clipboard.convert({
            html: formattedValue,
          });

          quillRef.current.setContents(html);
          setInitialValue(quillRef.current?.root.innerHTML || '');
        }

        if (formattedValue !== quillRef.current.root.innerHTML) {
          const selection = quillRef.current.getSelection();
          const html = quillRef.current.clipboard.convert({
            html: formattedValue,
          });

          quillRef.current.setContents(html, 'silent');

          if (selection) {
            setTimeout(() => {
              quillRef.current?.setSelection(selection);
            }, 1);
          }
        }
      }
    }, [value, placeholder]);

    useEffect(() => {
      if (quillRef.current) {
        quillRef.current.on('text-change', () => {
          const changedText = quillRef.current?.root.innerHTML || '';

          if (onChange) {
            onChange(changedText);
          }
        });

        quillRef.current.on('selection-change', (range, oldRange) => {
          if (range === null && oldRange !== null) {
            const currentValue = quillRef.current?.root.innerHTML || '';
            const strippedCurrentValue = currentValue.replace(/<[^>]*>/g, '').trim();

            if (onBlur) {
              if (preventBlank && strippedCurrentValue === '') {
                const html = quillRef.current?.clipboard.convert({
                  html: formattedValue,
                });

                if (html) {
                  quillRef.current?.setContents(html);
                  onBlur('', initialValue);
                }
              } else {
                onBlur(
                  initialValue !== currentValue && value !== currentValue ? currentValue : '',
                  currentValue,
                );
              }
            }
          }
        });

        return () => {
          quillRef.current?.off('text-change');
          quillRef.current?.off('selection-change');
        };
      }

      return () => {};
    }, [value, onChange, onBlur, initialValue, preventBlank]);

    return (
      <div
        aria-label={ariaLabel}
        className={className}
        data-testid="editor"
        ref={editorRef}
        {...restProps}
      />
    );
  },
);

EditorComponent.displayName = 'Editor';

export const Editor = memo(EditorComponent);
