import { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAnimationControls } from 'framer-motion';

import { NavMenuContext } from './nav-menu.context';
import { NavMenuState } from './nav-menu.model';

export interface NavMenuProviderProps {
  children: ReactNode;
  onLinkSelect?: (link: string) => void;
  selectedLink?: string;
}

export function NavMenuProvider({ children, onLinkSelect, selectedLink }: NavMenuProviderProps) {
  const { organ } = useParams<{ organ: string }>();
  const currentOrgan = organ?.replace(/-/g, '_') ?? selectedLink;

  const [selectedOrgan, setSelectedOrgan] = useState('');
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [hoveredMenu, setHoveredMenu] = useState<null | string>(null);
  const [isSubmenuHovered, setIsSubmenuHovered] = useState(false);
  const linkItemRefs = useRef<(HTMLDivElement | null)[]>([]);
  const hoverTimeoutRef = useRef<NodeJS.Timeout>();

  const containerControls = useAnimationControls();

  const handleCollapseChange = useCallback((value: boolean) => setIsCollapsed(value), []);

  const handleOrganSelect = useCallback((value: string) => setSelectedOrgan(value), []);

  const handleHoveredMenuChange = useCallback((value: null | string) => setHoveredMenu(value), []);

  const handleSubmenuHoverChange = useCallback((value: boolean) => setIsSubmenuHovered(value), []);

  useEffect(() => {
    if (selectedLink) {
      setSelectedOrgan(selectedLink);
    }
  }, [selectedLink]);

  useEffect(() => {
    if (isCollapsed) {
      containerControls.start('close');
    } else {
      containerControls.start('open');
    }
  }, [isCollapsed]);

  useEffect(() => {
    if (selectedOrgan) {
      const linkItem = linkItemRefs.current.find(
        (item) => item?.getAttribute('data-selected') === 'true',
      );

      if (linkItem) {
        linkItem.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }
    }
  }, [selectedOrgan]);

  const value: NavMenuState = useMemo(
    () => ({
      containerControls,
      currentOrgan,
      hoveredMenu,
      hoverTimeoutRef,
      isCollapsed,
      isSubmenuHovered,
      linkItemRefs,
      onCollapseChange: handleCollapseChange,
      onHoveredMenuChange: handleHoveredMenuChange,
      onLinkSelect,
      onOrganSelect: handleOrganSelect,
      onSubmenuHoverChange: handleSubmenuHoverChange,
      selectedOrgan,
    }),
    [
      currentOrgan,
      containerControls,
      hoveredMenu,
      hoverTimeoutRef,
      isCollapsed,
      isSubmenuHovered,
      linkItemRefs,
      handleCollapseChange,
      handleOrganSelect,
      handleHoveredMenuChange,
      handleSubmenuHoverChange,
      onLinkSelect,
      selectedOrgan,
    ],
  );

  return <NavMenuContext.Provider value={value}>{children}</NavMenuContext.Provider>;
}
