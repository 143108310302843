import { ConditionTemplate, Finding, FindingsByOrgan, RenderedSummary } from '@/api';
import { ML_SERVER } from '@/api/mocks/constants';

import { MIN_FINDING_HIGH_SCORE } from '../constant';
import { OrganLink } from '../model';

export const groupFindingsByOrgan = (data: RenderedSummary[]): { [key: string]: Finding[] } => {
  const groupedData: { [key: string]: Finding[] } = {};

  data.forEach((item) => {
    if (item.reportFindings.length === 0) {
      return;
    }

    const organ = item?.reportFindings[0]?.template?.organ || '';

    if (!groupedData[organ]) {
      groupedData[organ] = [];
    }

    const renderedSummaryData: Finding = {
      conditionTemplate: {
        condition: item.reportFindings[0].template?.condition,
        conditionDetail: item.reportFindings[0].template?.conditionDetail,
        normalStatementOutcome: item.reportFindings[0].template?.normalStatementOutcome,
        organ,
        uuid: item.reportFindings[0].template?.uuid,
      },
      renderedSummary: {
        actionDetails: item.actionDetails,
        actionSummary: item.actionSummary,
        aiAcknowledged: item.aiAcknowledged,
        createdBy: item.createdBy,
        csdScore: item.csdScore,
        findingDetails: item.findingDetails,
        findingSummary: item.findingSummary,
        keyImages: item.keyImages,
        newFinding: item.newFinding,
        oncoradsScore: item.oncoradsScore,
        reportFindings: item.reportFindings,
        reportFindingsUuids: item.reportFindingsUuids,
        reportUuid: item.reportUuid,
        status: '',
        uuid: item.uuid,
      },
    };

    groupedData[organ].push(renderedSummaryData);
  });

  return groupedData;
};

export const removeDuplicateConditions = (findings: Finding[]): Finding[] => {
  if (findings) {
    const seenConditions = new Set<string>();
    const dedupedFindings: Finding[] = [];

    findings.forEach((finding) => {
      const { condition } = finding.conditionTemplate;

      if (condition && !seenConditions.has(condition)) {
        dedupedFindings.push(finding);
        seenConditions.add(condition);
      } else if (finding.renderedSummary.createdBy !== ML_SERVER) {
        const index = dedupedFindings.findIndex((f) => f.conditionTemplate.condition === condition);

        if (index !== -1) {
          dedupedFindings[index] = finding;
        }
      }
    });

    return dedupedFindings;
  }

  return [];
};

export const categorizeFindings = (
  findings: Finding[],
): { commonFindings: Finding[]; defaultFindings: Finding[] } => {
  const defaultFindings: Finding[] = [];
  const commonFindings: Finding[] = [];

  findings.forEach((finding) => {
    const { condition } = finding.conditionTemplate;

    if (condition?.startsWith('default_question')) {
      defaultFindings.push(finding);
    } else {
      commonFindings.push(finding);
    }
  });

  return { commonFindings, defaultFindings: removeDuplicateConditions(defaultFindings) };
};

export const getFindingsWithHighScores = (data: {
  [key: string]: Finding[];
}): { [key: string]: Finding[] } => {
  const result: { [key: string]: Finding[] } = {};

  Object.keys(data).forEach((key) => {
    const organData = data[key];

    organData.forEach((item) => {
      const { csdScore, oncoradsScore } = item.renderedSummary;

      if (oncoradsScore > MIN_FINDING_HIGH_SCORE || csdScore > MIN_FINDING_HIGH_SCORE) {
        result[key] = [...(result[key] ?? []), item];
      }
    });
  });

  return result;
};

export const getConditionTemplates = (
  findings: FindingsByOrgan,
): {
  [key: string]: ConditionTemplate[];
} =>
  Object.entries(findings).reduce<{ [key: string]: ConditionTemplate[] }>(
    (acc, [organ, organFindings]) => {
      if (organ === 'spine') {
        acc[organ] = organFindings
          .map((finding) => finding.conditionTemplate as ConditionTemplate)
          .filter((finding) => finding.condition !== 'Spondyloarthropathy');
      } else {
        acc[organ] = organFindings.map((finding) => finding.conditionTemplate as ConditionTemplate);
      }

      return acc;
    },
    {},
  );

export const mergeFindings = (
  links: OrganLink[],
  defaultFindings: FindingsByOrgan,
  findings: FindingsByOrgan,
): FindingsByOrgan =>
  links.reduce((acc, navData) => {
    const organKey = navData.key;
    const organFindings = findings[organKey] || [];
    const defaultOrganFindings = defaultFindings[organKey] || [];

    acc[organKey] = [...defaultOrganFindings, ...organFindings];

    if (navData.sub?.length) {
      navData.sub.forEach((subOrgan) => {
        const subOrganKey = subOrgan.key;
        const subOrganFindings = findings[subOrganKey] || [];
        const defaultSubOrganFindings = defaultFindings[subOrganKey] || [];

        acc[subOrganKey] = [...defaultSubOrganFindings, ...subOrganFindings].map((finding) => ({
          ...finding,
          parentOrgan: organKey,
        }));
      });
    }

    return acc;
  }, {} as FindingsByOrgan);

export const isFieldAiGenerated = ({
  aiFindings,
  condition,
  name,
  organ,
  value,
}: {
  aiFindings: { [key: string]: Finding[] } | null;
  condition: string;
  name: string;
  organ: string;
  value: string;
}): boolean => {
  if (!aiFindings?.[organ]) {
    return false;
  }

  const finding = aiFindings[organ].find(
    (selectedFinding) => selectedFinding.conditionTemplate.condition === condition,
  );

  const conditionDetail = finding?.renderedSummary.reportFindings[0].conditionDetail;
  const selectedCondition = conditionDetail?.find((conditionData) => conditionData.name === name);

  if (Array.isArray(selectedCondition?.value)) {
    const selectedValues = selectedCondition?.value.map((v) => v.toLowerCase());

    return selectedValues.includes(value.toLowerCase());
  }

  return selectedCondition?.value === value;
};
