import { ReactNode, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { CONFIG } from '@/core/config';
import { useAuthStore } from '@/store';

interface AuthGuardProps {
  children: ReactNode;
}

export function AuthGuard({ children }: AuthGuardProps) {
  const [requestedLocation, setRequestedLocation] = useState<null | string>(null);

  const { isAuthenticated } = useAuthStore();
  const { pathname } = useLocation();

  // if not authenticated, redirect to login page
  if (!isAuthenticated) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }

    window.location.replace(`${CONFIG.APPS_SERVER}/login?redirectUrl=${window.location.href}`);

    return null;
  }

  // if authenticated and requested location is not null, navigate to the requested location
  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);

    return <Navigate to={requestedLocation} />;
  }

  // if authenticated, render the children
  return children;
}
