import { ML_SERVER } from '@/api/mocks/constants';
import { GroupedFindings } from '@/api/model';

export const extractMlServerFindings = (groupedData: GroupedFindings): GroupedFindings =>
  Object.fromEntries(
    Object.entries(groupedData)
      .map(([organ, mlFindings]) => [
        organ,
        mlFindings.filter((aiFinding) => aiFinding.renderedSummary.createdBy === ML_SERVER),
      ])
      .filter((mlFindings) => mlFindings.length > 0),
  );
