import { ReactNode } from 'react';

import { User } from '@/api';

import { InvalidRoles } from './invalid-roles.component';
import { VALID_ROLES } from './role.constant';
import { UserProvider } from './user.provider';

interface RoleGuardProps {
  children: ReactNode;
  user: User;
}

export function RoleGuard({ children, user }: RoleGuardProps) {
  if (user.roles.some((role) => VALID_ROLES.includes(role))) {
    return <UserProvider user={user}>{children}</UserProvider>;
  }

  return <InvalidRoles />;
}
