import { ReactNode } from 'react';

import { api } from '@/api';
import { Loading } from '@/shared/ui';

import { CONFIG } from '../config';
import { RoleGuard } from './role.guard';

interface UserStateProps {
  children: ReactNode;
}

export function UserState({ children }: UserStateProps) {
  const { data, error, isError, isLoading } = api.pii.user.query.useGetUser();

  if (isLoading) {
    return <Loading />;
  }

  if (error && isError) {
    window.location.replace(`${CONFIG.APPS_SERVER}/login?redirectUrl=${window.location.href}`);

    return null;
  }

  return <RoleGuard user={data!.user}>{children}</RoleGuard>;
}
