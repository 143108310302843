import { Location } from 'react-router-dom';

function determineNonOrganLink({ pathname }: Location) {
  const path = pathname.split('/').at(-1);

  switch (path) {
    case 'final-impressions':
      return 'favorite';
    case 'key-images':
      return 'key_images';
    case 'preview':
      return 'preview';
    case 'retrieve-key-images':
      return 'retrieve_key_images';
    default:
      return 'reason_for_scan';
  }
}

export function getSelectedLink(location: Location, organ?: string) {
  return organ ? organ.replace(/-/g, '_') : determineNonOrganLink(location);
}
