import { create } from 'zustand';

import { OrganValidationState, OrganValidationStatus } from './use-organ-validation.model';

export const useOrganValidationStore = create<OrganValidationState>((set, get) => ({
  clearAllStatus: () => {
    set({
      organs: {},
    });
  },
  defaultQuestionStatus: {},
  getStatus: (organ) => get().organs[organ],
  organs: {},
  setDefaultQuestionStatus: (organ: string, condition: string, validStatus: boolean) => {
    set((state) => ({
      defaultQuestionStatus: {
        ...state.defaultQuestionStatus,
        [organ]: {
          ...state.defaultQuestionStatus[organ],
          [condition]: validStatus,
        },
      },
    }));
  },
  setStatus: (organ: string, status: OrganValidationStatus) => {
    set((state) => ({
      organs: {
        ...state.organs,
        [organ]: status,
      },
    }));
  },
}));
