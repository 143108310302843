import { cn, IconButton, Tooltip } from '@prenuvo/halo-foundation';
import { CloseSidebar, OpenSidebar } from '@prenuvo/halo-icon';
import { AnimatePresence, motion } from 'framer-motion';

import { useFormStructure } from '@/shared/guard';
import { Skeleton } from '@/shared/ui';

import { MOTION_CONTAINER_VARIANTS } from './nav-menu.constant';
import { useNavMenu } from './nav-menu-context';
import { NavMenuLink } from './nav-menu-link.component';
import { SubMenuPopover } from './sub-menu-popover.component';

export function NavMenu() {
  const { links } = useFormStructure();

  const { containerControls, hoveredMenu, isCollapsed, onCollapseChange } = useNavMenu();

  const subMenuLink = links.find((link) => link.key === hoveredMenu && link.sub?.length);
  const previewLink = links.find((link) => link.key === 'preview')!;

  const show = {
    subMenuPopover: isCollapsed && hoveredMenu && !!subMenuLink,
  };

  return (
    <AnimatePresence>
      <motion.div
        animate={containerControls}
        className="relative"
        data-collapsed={isCollapsed}
        data-testid="nav-menu"
        variants={MOTION_CONTAINER_VARIANTS}
      >
        <nav
          className={cn(
            'rounded-2xl bg-stone-900 dark:bg-stone-900 pr-1 pt-1 overflow-hidden h-full flex flex-col justify-between',
            isCollapsed && `justify-items-center`,
          )}
        >
          {links.length === 0 ? (
            <div className="mt-2 flex flex-col gap-4 overflow-y-auto px-2">
              <Skeleton className="h-8" count={24} />
            </div>
          ) : (
            <div className="custom-scrollbar grid w-full grid-flow-row auto-rows-max grid-cols-none overflow-y-auto overflow-x-hidden pb-3">
              {links
                .filter(({ key }) => key !== 'preview')
                .map((link, index) => (
                  <NavMenuLink key={link.key} index={index} isSubmenuItem={false} link={link} />
                ))}
            </div>
          )}
          <div>
            <div className="border-t border-dashed border-stone-700 py-1 dark:border-stone-700">
              <NavMenuLink index={0} isSubmenuItem={false} link={previewLink} />
            </div>

            <div
              className={cn(
                isCollapsed && 'w-full place-content-center',
                'py-1 px-4 border-t border-dashed border-stone-700 dark:border-stone-700',
                !isCollapsed && 'flex justify-center',
              )}
            >
              <Tooltip
                className="bg-stone-700 text-xs text-neutral-50 dark:bg-stone-700 dark:text-neutral-50"
                content={isCollapsed ? 'Expand sidebar' : 'Collapse sidebar'}
                side="bottom"
              >
                <IconButton
                  aria-label="Toggle sidebar"
                  className={{
                    icon: 'fill-neutral-400 hover:fill-neutral-50',
                  }}
                  data-testid="menu-toggle-button"
                  icon={isCollapsed ? OpenSidebar : CloseSidebar}
                  onClick={() => onCollapseChange(!isCollapsed)}
                  size="md"
                  variant="text"
                />
              </Tooltip>
            </div>
          </div>
        </nav>

        {show.subMenuPopover && <SubMenuPopover subMenuLink={subMenuLink!} />}
      </motion.div>
    </AnimatePresence>
  );
}
