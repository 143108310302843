import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { ENDPOINTS } from '@/api/endpoints';
import { fetcher } from '@/api/fetcher';
import { PatientError, PatientRequest, PatientResponse } from '@/api/model';
import { removeEmptyParams } from '@/api/utils';

const KEY = {
  GET_PATIENT: 'GET_PATIENT',
} as const;

const request = {
  getPatient: async ({ payload: { params } }: PatientRequest, signal?: AbortSignal) => {
    const { data } = await fetcher.PII.get<PatientResponse>(ENDPOINTS.PII.PATIENT, {
      params: removeEmptyParams(params),
      signal,
    });

    return data;
  },
};

const query = {
  useGetPatient: (
    { payload }: PatientRequest,
    config?: UseQueryOptions<PatientResponse, PatientError>,
  ) =>
    useQuery<PatientResponse, PatientError>({
      enabled: !!payload.params.user_id,
      queryFn: ({ signal }) => request.getPatient({ payload }, signal),
      queryKey: [KEY.GET_PATIENT, payload.params.user_id],
      ...config,
    }),
};

export const patient = {
  KEY,
  query,
  request,
};
