import { useKeyImagesStore, useOrganValidationStore, useReportStore } from '@/store';

export function useUpdateKeyImagesStatus() {
  const { report } = useReportStore();
  const { setStatus } = useOrganValidationStore();
  const { keyImageLabels, keyImages } = useKeyImagesStore();

  const nonDeprecatedKeyImageLabels = keyImageLabels.filter(
    (label) => label.status !== 'deprecated',
  );

  const excludedSet = new Set(report?.excludeDicomImages || []);
  const labeledSet = new Set(
    nonDeprecatedKeyImageLabels
      .filter((image) => image.imageKey && image.instanceKey)
      .map((label) => label.imageKey),
  );
  const processedCount = keyImages.reduce(
    (count, image) => count + (excludedSet.has(image.id) || labeledSet.has(image.id) ? 1 : 0),
    0,
  );

  return (currentPage: boolean = false) => {
    const isDone = processedCount === keyImages.length || currentPage;
    const hasError =
      processedCount !== keyImages.length ||
      nonDeprecatedKeyImageLabels.length > keyImageLabels.length ||
      nonDeprecatedKeyImageLabels.length !== labeledSet.size;

    setStatus('key_images', {
      done: isDone,
      error: hasError,
      warning: false,
    });
  };
}
