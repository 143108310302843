import { useMutation, useQuery, UseQueryOptions } from '@tanstack/react-query';

import { ENDPOINTS } from '@/api/endpoints';
import { fetcher } from '@/api/fetcher';
import {
  PatchReportError,
  PatchReportRequest,
  PatchReportResponse,
  ReportError,
  ReportRequest,
  ReportResponse,
} from '@/api/model';
import { removeEmptyParams } from '@/api/utils';

const KEY = {
  GET_REPORT_STUDY: 'GET_REPORT_STUDY',
} as const;

const request = {
  getReportStudy: async ({ payload: { params, studyId } }: ReportRequest, signal?: AbortSignal) => {
    const { data } = await fetcher.FCT.get<ReportResponse>(
      `${ENDPOINTS.FCT.REPORT.STUDY}${studyId}`,
      { params: removeEmptyParams(params), signal },
    );

    return data;
  },
  patchReport: async ({ payload }: PatchReportRequest) => {
    const { data } = await fetcher.FCT.patch<PatchReportResponse>(
      `${ENDPOINTS.FCT.REPORT.BASE}${payload.studyId}`,
      payload,
    );

    return data;
  },
};

const query = {
  useGetReportStudy: (
    { payload }: ReportRequest,
    config?: UseQueryOptions<ReportResponse, ReportError>,
  ) =>
    useQuery<ReportResponse, ReportError>({
      enabled: !!payload.params.study_sku,
      queryFn: ({ signal }) => request.getReportStudy({ payload }, signal),
      queryKey: [KEY.GET_REPORT_STUDY, payload.studyId, payload.params.user_id],
      ...config,
    }),
};

const mutation = {
  usePatchReport: () =>
    useMutation<PatchReportResponse, PatchReportError, PatchReportRequest>({
      mutationFn: request.patchReport,
    }),
};

export const report = {
  KEY,
  mutation,
  query,
  request,
};
