import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { ENDPOINTS } from '@/api/endpoints';
import { fetcher } from '@/api/fetcher';
import { ActivityLogsError, ActivityLogsRequest, ActivityLogsResponse } from '@/api/model';
import { removeEmptyParams } from '@/api/utils';

const KEY = {
  GET_ACTIVITY_LOGS: 'GET_ACTIVITY_LOGS',
} as const;

const request = {
  getActivityLogs: async ({ payload: { params } }: ActivityLogsRequest, signal?: AbortSignal) => {
    const { data } = await fetcher.FCT.get<ActivityLogsResponse>(ENDPOINTS.FCT.ACTIVITY_LOG, {
      params: removeEmptyParams(params),
      signal,
    });

    return data;
  },
};

const query = {
  useGetActivityLogs: (
    { payload }: ActivityLogsRequest,
    config?: UseQueryOptions<ActivityLogsResponse, ActivityLogsError>,
  ) =>
    useQuery<ActivityLogsResponse, ActivityLogsError>({
      enabled: !!payload.params.report_uuid,
      queryFn: ({ signal }) => request.getActivityLogs({ payload }, signal),
      queryKey: [KEY.GET_ACTIVITY_LOGS, payload.params.study_id, payload.params.report_uuid],
      ...config,
    }),
};

export const activityLogs = {
  KEY,
  query,
  request,
};
