import { InformationCircle, QuestionCircle } from '@prenuvo/halo-icon';

import { FormStructure, FormStructureKey, FormStructureSku } from '@/api';
import { Organ, OrganItem, OrganLink } from '@/shared/model';

import {
  FEMALE_ORGANS,
  FORM_STRUCTURE_FULL_LIST,
  MALE_ORGANS,
  NAV_ICON_MAP,
  NavIconKey,
  NECK_DESIRED_ORDER,
} from './form-structure.constant';

const SKU_MAPPINGS: Record<string, FormStructureSku> = {
  '360': '350',
  '370': '350',
};

export function determineSku(skuId: string): FormStructureSku {
  return SKU_MAPPINGS[skuId] || skuId;
}

export const generateOrganLinks = (items: OrganItem[]): OrganLink[] => {
  const links: OrganLink[] = items.map(({ key, disabled, sub, value: title }) => {
    const subItems =
      key === 'neck' && sub
        ? [...sub].sort(
            (a, b) => NECK_DESIRED_ORDER.indexOf(a.key) - NECK_DESIRED_ORDER.indexOf(b.key),
          )
        : sub;

    return {
      key,
      disabled,
      icon: NAV_ICON_MAP[key as NavIconKey] || QuestionCircle,
      progress: null,
      sub: subItems,
      title,
    };
  });

  const additionalLinks: OrganLink[] = [
    {
      key: 'reason_for_scan',
      icon: InformationCircle,
      progress: null,
      title: 'Reason for a scan',
    },
    {
      key: 'retrieve_key_images',
      icon: NAV_ICON_MAP.retrieve_key_images,
      progress: null,
      title: 'Retrieve key images',
    },
    {
      key: 'favorite',
      icon: NAV_ICON_MAP.favorite,
      progress: null,
      title: 'Final Impressions',
    },
    {
      key: 'key_images',
      icon: NAV_ICON_MAP.key_images,
      progress: null,
      title: 'Key Images',
    },
    {
      key: 'preview',
      icon: NAV_ICON_MAP.preview,
      progress: null,
      title: 'Preview Report',
    },
  ];

  return [additionalLinks[0], ...links, ...additionalLinks.slice(1)];
};

/**
 * Parses the given form structure and returns an Organ object with the appropriate structure
 * based on the provided gender.
 *
 * @param {FormStructure[]} formStructure - The array of form structures to parse.
 * @param {string} gender - The gender to filter the organ structure by. Can be 'male' or 'female'.
 * @returns {Organ} The parsed Organ object with the appropriate structure.
 */
export const parseFormStructureToOrgan = (
  formStructure: FormStructure[],
  gender: string,
): Organ => {
  const { structure, ...currentVersion } = formStructure.reduce((max, current) =>
    max.version > current.version ? max : current,
  );

  const existingItemsMap = new Map(structure.map((item) => [item.key, true]));

  const mutatedStructure: OrganItem[] = FORM_STRUCTURE_FULL_LIST.map((item) => {
    const exists = existingItemsMap.has(item.key);

    return exists
      ? { ...structure.find((s) => s.key === item.key)!, disabled: false }
      : { ...item, disabled: true };
  })
    .map((item) => {
      if (item?.disabled && item?.sub) {
        return {
          ...item,
          sub: item.sub.map((subItem) => ({ ...subItem, disabled: true })),
        };
      }

      return item;
    })
    .filter((organ) => {
      const lowerKey = organ.key.toLowerCase();

      if (gender !== 'male' && MALE_ORGANS.includes(lowerKey as FormStructureKey)) {
        return false;
      }

      if (gender !== 'female' && FEMALE_ORGANS.includes(lowerKey as FormStructureKey)) {
        return false;
      }

      return true;
    });

  return {
    ...currentVersion,
    structure: mutatedStructure,
  };
};
