import { create } from 'zustand';

import { GroupedFindings } from '@/api/model';

type AIDefaultValuesState = {
  aiFindings: GroupedFindings;
  setAIFindings: (findings: GroupedFindings) => void;
};

export const useAIDefaultValuesStore = create<AIDefaultValuesState>((set) => ({
  aiFindings: {},
  setAIFindings: (findings) => {
    set({ aiFindings: findings });
  },
}));
