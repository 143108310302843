import { ReactNode, useCallback, useMemo } from 'react';

import { OrganLink } from '@/shared/model';

import { FormStructureContext } from './form-structure.context';
import { FormStructureState } from './form-structure.model';

export interface FormStructureProviderProps {
  children: ReactNode;
  links: OrganLink[];
  uuid?: string;
}

export function FormStructureProvider({ children, links = [], uuid }: FormStructureProviderProps) {
  const getLinkByOrgan = useCallback(
    (organ: string) => links.find(({ key }) => key === organ),
    [links],
  );

  const getLinkByOrganWithSub = useCallback(
    (organ: string) =>
      links
        .find((data) => data.sub?.some((sub) => sub.key === organ))
        ?.sub?.find((sub) => sub.key === organ),
    [links],
  );

  const value: FormStructureState = useMemo(
    () => ({
      getLinkByOrgan,
      getLinkByOrganWithSub,
      links,
      uuid,
    }),
    [links, uuid, getLinkByOrgan, getLinkByOrganWithSub],
  );

  return <FormStructureContext.Provider value={value}>{children}</FormStructureContext.Provider>;
}
