import { useState } from 'react';

import { api } from '@/api';
import { useUser } from '@/core/user';
import { createJiraSubmitter } from '@/shared/util/';

import { AboutType, IssueType } from './report-issue.model';
import { getShortAboutType } from './report-issue.util';

export function useReportIssue() {
  const [issueType, setIssueType] = useState<IssueType>();
  const [aboutType, setAboutType] = useState<AboutType>();
  const [title, setTitle] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [attachedFiles, setAttachedFiles] = useState<File[]>([]);
  const [showErrors, setShowErrors] = useState(false);
  const [showReportIssue, setShowReportIssue] = useState(false);
  const { user } = useUser();

  const {
    isError: isReportIssueError,
    isPending: isReportIssuePending,
    isSuccess: isReportIssueSuccess,
    mutateAsync: reportIssue,
    reset: resetReportIssue,
  } = api.fct.reportIssue.mutation.usePostReportIssue();

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(event.target.files || []);
    const validFiles = files.filter((file) => {
      const isValidType = ['image/jpeg', 'image/png', 'image/gif'].includes(file.type);
      const isValidSize = file.size <= 5 * 1024 * 1024;

      return isValidType && isValidSize;
    });

    setAttachedFiles((prev) => [...prev, ...validFiles]);
  };

  const removeFile = (index: number) => {
    setAttachedFiles((prev) => prev.filter((_, i) => i !== index));
  };

  const resetForm = () => {
    setIssueType(undefined);
    setAboutType(undefined);
    setTitle('');
    setDescription('');
    setAttachedFiles([]);
  };

  const handleSubmit = async () => {
    if (!title || !issueType) {
      setShowErrors(true);

      return;
    }

    const jiraSubmitter = createJiraSubmitter({
      email: user.email,
      name: user.fullname,
      prenuvoID: user.prenuvoID,
    });

    const postData = jiraSubmitter({
      description,
      issueTypeName: issueType === IssueType.REPORT_ISSUE ? 'Bug' : 'Story',
      projectKey: 'FC',
      title: `${aboutType ? getShortAboutType(aboutType) : ''} ${title}`,
    });

    try {
      await reportIssue({ payload: postData });
      resetForm();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Failed to create issue:', error);
    }
  };

  return {
    aboutType,
    attachedFiles,
    description,
    handleFileChange,
    handleSubmit,
    isReportIssueError,
    isReportIssuePending,
    isReportIssueSuccess,
    issueType,
    removeFile,
    resetForm,
    resetReportIssue,
    setAboutType,
    setDescription,
    setIssueType,
    setShowErrors,
    setShowReportIssue,
    setTitle,
    showErrors,
    showReportIssue,
    title,
  };
}
