import { SKU_PACKAGE_MAP } from '../constant';

export function getSkuPackage(value: number) {
  const sku = SKU_PACKAGE_MAP[value];

  if (sku) {
    return sku;
  }

  return value !== 0 ? `SKU: ${value}` : 'SKU: Unknown';
}

export function extractSkuFromSkuId(value: string) {
  return Number(value.slice(-8));
}
