import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { ENDPOINTS } from '@/api/endpoints';
import { fetcher } from '@/api/fetcher';
import {
  FormStructuresError,
  FormStructuresRequest,
  FormStructuresResponse,
} from '@/api/model/fct/form-structure.model';
import { removeEmptyParams } from '@/api/utils';

const KEY = {
  GET_CONDITION_TEMPLATES: 'GET_CONDITION_TEMPLATES',
} as const;

const request = {
  getFormStructures: async (
    { payload: { params } }: FormStructuresRequest,
    signal?: AbortSignal,
  ) => {
    const { data } = await fetcher.FCT.get<FormStructuresResponse>(ENDPOINTS.FCT.FORM_STRUCTURE, {
      params: removeEmptyParams(params),
      signal,
    });

    return data;
  },
};

const query = {
  useGetFormStructures: (
    { payload }: FormStructuresRequest,
    config?: UseQueryOptions<FormStructuresResponse, FormStructuresError>,
  ) =>
    useQuery<FormStructuresResponse, FormStructuresError>({
      enabled: !!payload.params.sku,
      queryFn: ({ signal }) => request.getFormStructures({ payload }, signal),
      queryKey: [
        KEY.GET_CONDITION_TEMPLATES,
        payload.params.sku,
        payload.params.status,
        payload.params.version,
      ],
      ...config,
    }),
};

export const formStructure = {
  KEY,
  query,
  request,
};
