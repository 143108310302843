import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';

import { API_ENDPOINTS, RadiologistStudyKeyImagesResponse } from '@/api';
import { makeApiRequest } from '@/api/utils/make-api-request/makeApiRequest';
import { useStudyGuard } from '@/shared/guard';
import { useKeyImagesStore } from '@/store';

import { UseKeyImagesQueryOptions } from './useKeyImagesQuery.model';

export const useKeyImagesQuery = ({ allowRetries = false, enabled }: UseKeyImagesQueryOptions) => {
  const { studyId } = useStudyGuard();
  const { setIsGlobalKIRetrieving, setShouldRecurFetchKi } = useKeyImagesStore();

  const fetchKeyImagesManifest = async () => {
    const { data } = await makeApiRequest<RadiologistStudyKeyImagesResponse>(
      'HI',
      `${API_ENDPOINTS.hi.radStudy}${studyId}/ki-manifest?key_images=true&return_images=true&return_urls=true`,
      'GET',
    );

    if (!data || !data.key_images) {
      throw new Error('Invalid key images response');
    }

    const isProcessingComplete =
      data.key_images.isProcessing === '0' &&
      data.key_images.isProcessed === true &&
      data.key_images.isAvailable === true &&
      data.key_images.processed &&
      Object.keys(data.key_images.processed).length > 0;

    if (isProcessingComplete) {
      return data;
    }

    throw new Error(
      `Key images not ready: Processing=${data.key_images.isProcessing}, Processed=${data.key_images.isProcessed}, Available=${data.key_images.isAvailable}`,
    );
  };

  const query = useQuery({
    enabled,
    queryFn: fetchKeyImagesManifest,
    queryKey: ['keyImagesManifest', studyId],
    retry: (failureCount) => {
      if (!allowRetries) {
        return false;
      }

      if (failureCount < 5) {
        return true;
      }

      return false;
    },
    retryDelay: (attemptIndex) => Math.min(1000 * 2 ** attemptIndex + Math.random() * 1000, 30000),
    staleTime: 5 * 60 * 1000,
  });

  useEffect(() => {
    if (query.data || query.isError) {
      setIsGlobalKIRetrieving(false);
      setShouldRecurFetchKi(false);
    }
  }, [query.data, query.isError]);

  return { ...query, data: query.data, isLoading: query.isLoading };
};
