import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';

import { api } from '@/api';
import { Loading } from '@/shared/ui';
import { generateAppRoute } from '@/shared/util';

import { useStudyGuard } from '../study-guard';
import { FormStructureProvider } from './form-structure.provider';
import { determineSku, generateOrganLinks, parseFormStructureToOrgan } from './form-structure.util';

interface FormStructureGuardProps {
  children: ReactNode;
}

export function FormStructureGuard({ children }: FormStructureGuardProps) {
  const {
    patient: { gender },
    radiologistScan: { skuID },
  } = useStudyGuard();

  const { data, isError, isLoading } = api.fct.formStructure.query.useGetFormStructures({
    payload: {
      params: {
        sku: determineSku(skuID),
        status: 'draft',
      },
    },
  });

  if (isLoading) {
    return <Loading />;
  }

  if (isError || !data) {
    return <Navigate replace to={generateAppRoute('NOT_FOUND')} />;
  }

  const { structure, uuid } = parseFormStructureToOrgan(data, gender);

  const links = generateOrganLinks(structure);

  return (
    <FormStructureProvider links={links} uuid={uuid}>
      {children}
    </FormStructureProvider>
  );
}
