import { OrganLink } from '@/shared/model';
import { getFindingsWithHighScores, mergeFindings } from '@/shared/util';
import { useDefaultFindingStore, useFindingStore, useOrganValidationStore } from '@/store';

/**
 * A custom hook that evaluates the status of organ links and updates their validation states
 * based on findings and high-score warnings.
 *
 * The function performs the following:
 * - Merges findings from the provided links, default findings, and current findings.
 * - Identifies organs with high scores based on the merged findings.
 * - Updates the status of each organ and its sub-organs in the validation store, setting their
 *   `done`, `error`, and `warning` properties accordingly.
 */
export function useEvaluateLinks(links: OrganLink[]): () => void {
  const { getStatus, setStatus } = useOrganValidationStore();
  const { findings } = useFindingStore();
  const { defaultFindings } = useDefaultFindingStore();

  const mergedFindings = mergeFindings(links, defaultFindings, findings);
  const highScoreOrgans = getFindingsWithHighScores(mergedFindings);

  return () =>
    links.forEach(({ key, sub }) => {
      const organState = getStatus(key);
      const organWarning = Boolean(highScoreOrgans[key]?.length);

      if (sub && sub.length > 0) {
        sub.forEach(({ key: subKey }) => {
          const subOrganState = getStatus(subKey);
          const hasSubOrganWarning = Boolean(highScoreOrgans[subKey]?.length);

          setStatus(subKey, {
            ...subOrganState,
            done: subOrganState?.done ?? false,
            error: subOrganState?.error ?? false,
            warning: hasSubOrganWarning,
          });
        });
      }

      setStatus(key, {
        ...organState,
        done: organState?.done ?? false,
        error: organState?.error ?? false,
        warning: organWarning,
      });
    });
}
