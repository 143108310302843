import { create } from 'zustand';

import { ReportState } from './use-report.model';

export const useReportStore = create<ReportState>((set) => ({
  isReportLoading: false,
  report: null,
  setIsReportLoading: (isLoading) => set({ isReportLoading: isLoading }),
  setReport: (report) => set({ report }),
}));
