import { isRouteErrorResponse, useNavigate, useRouteError } from 'react-router-dom';
import { Button, Typography } from '@prenuvo/halo-foundation';

import { ReportIssue } from '../reporting/shared/component/report-issue';

export function ErrorBoundaryRoute() {
  const error = useRouteError();
  const navigate = useNavigate();

  // Handle known router errors
  if (isRouteErrorResponse(error)) {
    return (
      <div className="flex size-full flex-col gap-4 p-12">
        <Typography as="h1" variant="h4">
          {error.status} - {error.statusText}
        </Typography>
        <Typography>
          {error.data?.message || 'Sorry, something went wrong while loading this page.'}
        </Typography>
        <div className="flex items-center gap-2">
          <ReportIssue buttonSize="md" />
          <Button onClick={() => navigate(-1)} variant="primary">
            Go Back
          </Button>
        </div>
      </div>
    );
  }

  // Handle unknown errors
  return (
    <div className="flex size-full flex-col gap-4 p-12">
      <Typography as="h1" className="pb-4" variant="h4">
        Oops! Something went wrong.
      </Typography>
      <Typography>Sorry, an unexpected error occurred. Our team has been notified.</Typography>
      <Typography className="dark:text-red-500" variant="paragraph-md">
        {error instanceof Error ? error.stack : 'Unknown error'}
      </Typography>
      <div className="flex items-center gap-2 pt-10">
        <ReportIssue buttonSize="md" />
        <Button onClick={() => window.location.reload()} variant="primary">
          Refresh Page
        </Button>
      </div>
    </div>
  );
}
