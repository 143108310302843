import { create } from 'zustand';

import { Finding, FindingsByOrgan, UpdateFindingDetails } from '@/api';

export type FindingsState = {
  addFinding: (finding: Finding, shouldHighlight?: boolean) => FindingsByOrgan;
  deleteFinding: (organ: string, uuid: string) => FindingsByOrgan;
  findings: FindingsByOrgan;
  getFindingByConditionTemplate: (
    organ: string,
    conditionTemplate: string,
    renderedSummaryUuid: string,
  ) => Finding | null;
  getFindingsByOrgan: (organ: string) => Finding[];
  highlightedFinding: Finding | null;
  setFindings: (finding: FindingsByOrgan) => void;
  setHighlightedFinding: (finding: Finding) => void;
  updateFinding: (
    organ: string,
    updatedFinding: UpdateFindingDetails,
    renderedSummaryId: string,
  ) => FindingsByOrgan;
};

export const useFindingStore = create<FindingsState>((set, get) => ({
  addFinding: (finding: Finding, shouldHighlight = false) => {
    set((state) => {
      const updatedFindings = { ...state.findings };
      const organ = finding.conditionTemplate.organ || '';

      if (!updatedFindings[organ]) {
        updatedFindings[organ] = [];
      }

      updatedFindings[organ] = [...updatedFindings[organ], finding];

      return {
        ...state,
        findings: updatedFindings,
        highlightedFinding: shouldHighlight ? finding : state.highlightedFinding,
      };
    });

    return get().findings;
  },
  deleteFinding: (organ: string, uuid: string) => {
    set((state) => {
      const organFindings = state.findings[organ] || [];
      const updatedFindings = organFindings.filter(
        (finding) => finding.renderedSummary.uuid !== uuid,
      );

      return {
        findings: {
          ...state.findings,
          [organ]: updatedFindings,
        },
      };
    });

    return get().findings;
  },
  findings: {},
  getFindingByConditionTemplate: (
    organ: string,
    conditionTemplate: string,
    renderedSummaryUuid: string,
  ) => {
    const findings = get().getFindingsByOrgan(organ);

    return (
      findings.find(
        (finding) =>
          finding.conditionTemplate.condition === conditionTemplate &&
          finding.renderedSummary.uuid === renderedSummaryUuid,
      ) || null
    );
  },
  getFindingsByOrgan: (organ: string) => get().findings[organ] || [],
  highlightedFinding: null,
  setFindings: (findings: { [key: string]: Finding[] }) => {
    set((state) => ({
      findings: {
        ...state.findings,
        ...findings,
      },
    }));
  },
  setHighlightedFinding: (finding: Finding) => {
    set({ highlightedFinding: finding });
  },
  updateFinding: (
    organ: string,
    updatedFinding: UpdateFindingDetails,
    renderedSummaryId: string,
  ) => {
    set((state) => {
      const organFindings = state.findings[organ]?.map((finding) => {
        const isSameUuid = finding.renderedSummary.uuid === renderedSummaryId;

        if (isSameUuid) {
          return {
            ...finding,
            ...updatedFinding,
          };
        }

        return finding;
      });

      return {
        ...state,
        findings: {
          ...state.findings,
          [organ]: organFindings || [updatedFinding],
        },
      };
    });

    return get().findings;
  },
}));
