import {
  Adrenals,
  Ankles,
  Annotate,
  Bladder,
  Bowel,
  Brain,
  Breast,
  Esophagus,
  Gallbladder,
  Heart,
  Image,
  ImageDownload,
  InformationCircle,
  Kidneys,
  Knees,
  Liver,
  Lungs,
  Neck,
  Ovaries,
  Pancreas,
  Pelvis,
  Prostate,
  SacroliacJoints,
  Shoulders,
  Sinus,
  Skleleton,
  Spine,
  Spleen,
  Star,
  Stomach,
  Uterus,
} from '@prenuvo/halo-icon';

import { FormStructureKey, FormStructureSubKey, FromStructureItem } from '@/api';

export const NECK_DESIRED_ORDER: FormStructureSubKey[] = [
  'nasal_pharynx',
  'oral_pharynx',
  'hypopharynx',
  'thyroid',
  'neck_lymph_nodes',
  'cervical_lymph_node_chain',
];

export const FORM_STRUCTURE_FULL_LIST: FromStructureItem[] = [
  {
    key: 'brain',
    sub: [],
    value: 'Brain',
  },
  {
    key: 'sinuses_and_mastoids',
    sub: [],
    value: 'Sinuses',
  },
  {
    key: 'neck',
    sub: [
      {
        key: 'thyroid',
        value: 'Thyroid',
      },
      {
        key: 'hypopharynx',
        value: 'Hypopharynx',
      },
      {
        key: 'nasal_pharynx',
        value: 'Nasal Pharynx',
      },
      {
        key: 'cervical_lymph_node_chain',
        value: 'Cervical Lymph Node Chain',
      },
      {
        key: 'oral_pharynx',
        value: 'Oral Pharynx',
      },
    ],
    value: 'Neck',
  },
  {
    key: 'lungs_and_mediastinum',
    sub: [],
    value: 'Lungs',
  },
  {
    key: 'heart_and_great_vessels',
    sub: [],
    value: 'Heart and Vessels',
  },
  {
    key: 'breasts',
    sub: [],
    value: 'Breasts',
  },
  {
    key: 'esophagus',
    sub: [],
    value: 'Esophagus',
  },
  {
    key: 'stomach',
    sub: [],
    value: 'Stomach',
  },
  {
    key: 'liver',
    sub: [],
    value: 'Liver',
  },
  {
    key: 'gallbladder_and_biliary_system',
    sub: [],
    value: 'Gallbladder',
  },
  {
    key: 'pancreas',
    sub: [],
    value: 'Pancreas',
  },
  {
    key: 'kidneys',
    sub: [],
    value: 'Kidneys',
  },
  {
    key: 'adrenals',
    sub: [],
    value: 'Adrenals',
  },
  {
    key: 'spleen',
    sub: [],
    value: 'Spleen',
  },
  {
    key: 'bowel',
    sub: [],
    value: 'Bowel',
  },
  {
    key: 'bladder_and_ureters',
    sub: [],
    value: 'Bladder and Ureters',
  },
  {
    key: 'ovaries',
    sub: [],
    value: 'Ovaries',
  },
  {
    key: 'uterus',
    sub: [],
    value: 'Uterus',
  },
  {
    key: 'prostate',
    sub: [],
    value: 'Prostate',
  },
  {
    key: 'spine',
    sub: [],
    value: 'Spine',
  },
  {
    key: 'sacroiliac_joints',
    sub: [],
    value: 'Sacroiliac Joints',
  },
  {
    key: 'shoulders',
    sub: [],
    value: 'Shoulders',
  },
  {
    key: 'pelvis_and_hips',
    sub: [],
    value: 'Pelvis and Hips',
  },
  {
    key: 'knees',
    sub: [],
    value: 'Knees',
  },
  {
    key: 'ankles',
    sub: [],
    value: 'Ankles',
  },
  {
    key: 'bony_skeleton',
    sub: [],
    value: 'Skeleton and Soft Tissues',
  },
];

export const NAV_ICON_MAP = {
  adrenals: Adrenals,
  ankles: Ankles,
  bladder_and_ureters: Bladder,
  bony_skeleton: Skleleton,
  bowel: Bowel,
  brain: Brain,
  breasts: Breast,
  esophagus: Esophagus,
  favorite: Star,
  gallbladder_and_biliary_system: Gallbladder,
  heart_and_great_vessels: Heart,
  key_images: Image,
  kidneys: Kidneys,
  knees: Knees,
  liver: Liver,
  lungs_and_mediastinum: Lungs,
  neck: Neck,
  ovaries: Ovaries,
  pancreas: Pancreas,
  pelvis_and_hips: Pelvis,
  preview: Annotate,
  prostate: Prostate,
  reason_for_scan: InformationCircle,
  retrieve_key_images: ImageDownload,
  sacroiliac_joints: SacroliacJoints,
  shoulders: Shoulders,
  sinuses_and_mastoids: Sinus,
  spine: Spine,
  spleen: Spleen,
  stomach: Stomach,
  uterus: Uterus,
} as const;

export type NavIconKey = keyof typeof NAV_ICON_MAP;

export const FEMALE_ORGANS: FormStructureKey[] = ['breasts', 'uterus', 'ovaries'];

export const MALE_ORGANS: FormStructureKey[] = ['prostate'];
