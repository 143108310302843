/* eslint-disable no-console */
import { useEffect, useRef, useState } from 'react';
import { useQuery } from '@tanstack/react-query';

import { API_ENDPOINTS, makeApiRequest, StudiesResponse } from '@/api';
import { useStudyGuard } from '@/shared/guard';
import { getPreviousStudyIds, StudyResult, usePatientStudiesStore } from '@/store';

export const usePatientStudiesQuery = () => {
  const {
    radiologistScan: { patientID, scanDate },
    studyId,
  } = useStudyGuard();

  const hasPatientStudiesFetched = useRef(false);
  const [previousStudyIds, setPreviousStudyIds] = useState<StudyResult[]>([]);
  const { setPatientStudies } = usePatientStudiesStore();
  const url = `${API_ENDPOINTS.study.getStudy}?users=${patientID}&status=scanned`;

  const has = {
    patientId: patientID !== undefined,
    studyId: studyId !== undefined,
  };

  const enabled = has.patientId && !hasPatientStudiesFetched.current;

  const fetchPatientStudies = () => makeApiRequest<StudiesResponse>('APPS', url, 'GET');

  const {
    data: patientStudiesData,
    error,
    isLoading,
  } = useQuery({
    enabled,
    queryFn: fetchPatientStudies,
    queryKey: ['patientStudies', patientID],
    refetchOnWindowFocus: false,
    retryOnMount: false,
  });

  useEffect(() => {
    if (patientStudiesData) {
      setPatientStudies(patientStudiesData.data.studies);
      hasPatientStudiesFetched.current = true;

      if (patientStudiesData?.data?.studies?.length > 0) {
        const previousIds = getPreviousStudyIds(patientStudiesData.data.studies, studyId, scanDate);

        setPreviousStudyIds(previousIds);
      } else {
        console.log('No patient studies found');
      }
    }
  }, [patientStudiesData, hasPatientStudiesFetched]);

  return { error, isLoading, previousStudyIds };
};
