import { useEffect } from 'react';
import { Button, cn, IconButton, Tooltip } from '@prenuvo/halo-foundation';
import { Document, Duplicat } from '@prenuvo/halo-icon';

import { usePatientStudiesQuery } from '@/api/queries';
import { Logo } from '@/assets/icons';
import { CONFIG } from '@/core/config';
import { FCT } from '@/i18n';
import { useStudyGuard } from '@/shared/guard';
import { formatMonthDayYear, transformSnakeCaseToPascalCase } from '@/shared/util';

import { ReportIssue } from '../../../component';
import { extractSkuFromSkuId, getSkuPackage } from '../../../util';
import { calculateAge, formatTimestamp, initializeTabManagement } from './header.util';
import { ResetReportButton } from './reset-report-button.component';

export type HeaderProps = {
  className?: string;
};

export function Header({ className }: HeaderProps) {
  const {
    patient,
    radiologistScan: { machineID, scanDate, skuID },
    studyId,
  } = useStudyGuard();

  const SKU_PACKAGE = getSkuPackage(extractSkuFromSkuId(skuID));

  const { previousStudyIds } = usePatientStudiesQuery();

  useEffect(() => {
    if (!studyId) {
      return;
    }

    initializeTabManagement({ studyId });
  }, [studyId]);

  const infoItem = (label: string, value?: string) => (
    <span key={label} className="border-r-2 px-2 py-1">
      {label.toLocaleLowerCase() !== 'package' && (
        <>
          <span data-testid={`patient-${label}`}>{label === 'DOB' ? `~ ${label}` : label}</span>
          <span className="px-1">
            <span className="relative bottom-0.5 inline-flex size-1 rounded-full bg-neutral-400 dark:bg-neutral-400" />
          </span>
        </>
      )}
      <span data-testid={`patient-${label}-value`}>{value}</span>
    </span>
  );

  const staticInfoMapping = {
    DOB: FCT.REPORTING.HEADER.DOB(
      patient?.fake_dob,
      patient?.fake_dob ? calculateAge(patient.fake_dob) : undefined,
    ),
    'Scan Date': formatTimestamp(scanDate),
  };

  const responsiveInfoMapping = {
    Identifier: studyId?.slice(0, 8),
    Package: SKU_PACKAGE,
    Scanner: machineID || FCT.REPORTING.HEADER.UNKNOWN.SCANNER,
  };

  const responsiveList = (
    <div className="flex flex-col space-y-2">
      {Object.entries(responsiveInfoMapping).map(([label, value]) => (
        <span key={label} className="px-2 py-1">
          <span data-testid={`header-${label}`}>{label !== 'Package' && label}</span>
          {label !== 'Package' && (
            <span className="px-1">
              <span className="relative bottom-0.5 inline-flex size-1 rounded-full bg-neutral-400 dark:bg-neutral-400" />
            </span>
          )}
          <span data-testid={`header-${label}-value`}>{value}</span>
        </span>
      ))}
    </div>
  );

  const getFormattedPatientSummary = () => {
    const summary = [
      `${patient?.fake_lastname}, ${patient?.fake_firstname}`,
      `${patient?.gender ? transformSnakeCaseToPascalCase(patient?.gender) : FCT.REPORTING.HEADER.UNKNOWN.GENDER}`,
      `Approx DOB: ${patient?.fake_dob}`,
      patient?.fake_dob ? `Age: ${calculateAge(patient.fake_dob)}` : '',
      `Scan date: ${formatTimestamp(scanDate)}`,
      `Scanner: ${machineID || FCT.REPORTING.HEADER.UNKNOWN.SCANNER}`,
      `Identifier: ${studyId?.slice(0, 8)}`,
      `Package: ${SKU_PACKAGE}`,
    ]
      .filter(Boolean)
      .join('\n');

    return summary;
  };

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(getFormattedPatientSummary());
  };

  const displayFormattedSKU = (skuIds: string[]) =>
    skuIds.map((id) => getSkuPackage(Number(id))).join(' ');

  return (
    <div
      className={cn(
        'flex h-16 rounded-t-2xl bg-stone-900 dark:bg-stone-900 px-4 text-neutral-400 dark:text-neutral-400',
        className,
      )}
      data-testid="header-container"
    >
      <div className="flex items-center">
        <img alt="Prenuvo Logo" data-testid="header-icon" src={Logo} />
      </div>
      <div className="flex flex-1 items-center pl-2">
        <Tooltip content={FCT.REPORTING.HEADER.ACTIONS.COPY_STUDY_DETAILS} side="bottom">
          <IconButton
            aria-label={FCT.REPORTING.HEADER.ACTIONS.COPY_STUDY_DETAILS}
            data-testid="copy-patient-summary-button"
            icon={Duplicat}
            onClick={handleCopyToClipboard}
            size="sm"
            variant="text"
          />
        </Tooltip>
        <span className="flex pl-1 text-sm leading-none">
          <span className="border-r-2 py-1 pr-2" data-testid="patient-name">
            {patient?.fake_lastname && patient?.fake_firstname
              ? `${patient?.fake_lastname}, ${patient?.fake_firstname}`
              : FCT.REPORTING.HEADER.UNKNOWN.NAME}
          </span>
          <span className="border-r-2 px-2 py-1" data-testid="patient-gender">
            {patient?.gender
              ? transformSnakeCaseToPascalCase(patient?.gender)
              : FCT.REPORTING.HEADER.UNKNOWN.GENDER}
          </span>
          {Object.entries(staticInfoMapping).map(([label, value]) => infoItem(label, value))}
          <div className="hidden 2xl:flex">
            {Object.entries(responsiveInfoMapping).map(([label, value]) => infoItem(label, value))}
          </div>
          <div className="flex cursor-pointer pl-2 2xl:hidden" data-testid="patient-info-tooltip">
            <Tooltip content={responsiveList} side="bottom">
              <span className="rounded-md bg-stone-800 px-2 py-1">
                +{Object.entries(responsiveInfoMapping).length}
              </span>
            </Tooltip>
          </div>
        </span>
      </div>
      <div className="flex items-center">
        <ResetReportButton />
        <Button
          aria-label={FCT.REPORTING.HEADER.ACTIONS.MEDICAL_HISTORY}
          className={{ root: 'mx-4 bg-stone-700' }}
          data-testid="medical-history-button"
          leftIcon={{ source: Document }}
          onClick={() =>
            window.open(
              `${CONFIG.APPS_SERVER}/admin/study/${studyId}/medical-history/radiologist`,
              '_blank',
            )
          }
          size="sm"
          variant="text"
        >
          {FCT.REPORTING.HEADER.ACTIONS.PT_HX}
        </Button>
        {previousStudyIds.length > 0 && (
          <div className="flex flex-row gap-3" data-testid="previous-study-reports">
            {previousStudyIds.map(({ id, date, skuIDs }, index: number) => (
              <Tooltip
                key={`previous-report-${id}`}
                className="bg-stone-700 text-xs text-neutral-50 dark:bg-stone-700 dark:text-neutral-50"
                content={`${displayFormattedSKU(skuIDs)}, ${formatMonthDayYear(date)}`}
              >
                <div>
                  <Button
                    aria-label="PreviousReport"
                    className={{ root: 'bg-stone-700 px-3' }}
                    data-testid={`previous-report-btn-${index}`}
                    onClick={() =>
                      window.open(
                        `${CONFIG.APPS_SERVER}/radiologist/study/${id}/latest-pdf-report`,
                        '_blank',
                      )
                    }
                    size="sm"
                    variant="text"
                  >
                    P{index + 1}
                  </Button>
                </div>
              </Tooltip>
            ))}
          </div>
        )}
        <ReportIssue />
      </div>
    </div>
  );
}
