import { useMutation, useQuery, UseQueryOptions } from '@tanstack/react-query';

import { ENDPOINTS } from '@/api/endpoints';
import { fetcher } from '@/api/fetcher';
import {
  CreateReportPdfError,
  CreateReportPdfRequest,
  CreateReportPdfResponse,
  ReportPdfPreviewError,
  ReportPdfPreviewRequest,
  ReportPdfPreviewResponse,
} from '@/api/model';

const KEY = {
  GET_REPORT_PDF_PREVIEW: 'GET_REPORT_PDF_PREVIEW',
} as const;

const request = {
  getReportPdfPreviewData: async (
    { payload: { studyId } }: ReportPdfPreviewRequest,
    signal?: AbortSignal,
  ) => {
    const { data } = await fetcher.FCT.get<ReportPdfPreviewResponse>(
      `${ENDPOINTS.FCT.REPORT_PDF}study/${studyId}/preview-data`,
      { signal },
    );

    return data;
  },
  postCreateReportPdf: async ({ payload }: CreateReportPdfRequest) => {
    const { data } = await fetcher.FCT.post<CreateReportPdfResponse>(
      ENDPOINTS.FCT.REPORT_FINDING,
      payload,
    );

    return data;
  },
};

const query = {
  useGetReportPdfPreviewData: (
    { payload }: ReportPdfPreviewRequest,
    config?: UseQueryOptions<ReportPdfPreviewResponse, ReportPdfPreviewError>,
  ) =>
    useQuery<ReportPdfPreviewResponse, ReportPdfPreviewError>({
      queryFn: ({ signal }) => request.getReportPdfPreviewData({ payload }, signal),
      queryKey: [KEY.GET_REPORT_PDF_PREVIEW, payload.studyId],
      ...config,
    }),
};

const mutation = {
  usePostCreateReportPdf: () =>
    useMutation<CreateReportPdfResponse, CreateReportPdfError, CreateReportPdfRequest>({
      mutationFn: request.postCreateReportPdf,
    }),
};

export const reportPdf = {
  KEY,
  mutation,
  query,
  request,
};
