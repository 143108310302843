import { useEffect, useState } from 'react';
import { Navigate, useLocation, useParams } from 'react-router-dom';

import {
  useMedicalHistoryQuery,
  usePatientStudiesQuery,
  useReportQuery,
  useStudyMedicalHistoryQuery,
} from '@/api/queries';
import { Loading } from '@/shared/ui';
import { generateAppRoute } from '@/shared/util';

import { Header } from './header';
import { NavMenu, NavMenuProvider } from './nav-menu';
import { ReportingState } from './reporting-state.component';
import { useRouteValidation } from './use-route-validation.hook';
import { getSelectedLink } from './util';

export function ReportingLayout() {
  const [selectedLink, setSelectedLink] = useState<string>('');

  const { organ } = useParams();
  const location = useLocation();
  const isValidRoute = useRouteValidation();

  const { isLoading: isReportLoading } = useReportQuery();
  const { isLoading: isMedicalHistoryLoading } = useMedicalHistoryQuery();
  const { isLoading: isStudyMedicalHistoryLoading } = useStudyMedicalHistoryQuery();
  const { isLoading: isPatientStudiesLoading } = usePatientStudiesQuery();

  const isLoading =
    isReportLoading ||
    isMedicalHistoryLoading ||
    isStudyMedicalHistoryLoading ||
    isPatientStudiesLoading;

  const handleLinkSelect = (link: string) => {
    setSelectedLink(link);
  };

  useEffect(() => {
    setSelectedLink(getSelectedLink(location, organ));
  }, [organ, location]);

  if (isValidRoute) {
    return (
      <div className="flex h-screen flex-col">
        <Header />
        <div className="flex h-[calc(100%-5rem)] p-4 pb-0 pr-0">
          <NavMenuProvider onLinkSelect={handleLinkSelect} selectedLink={selectedLink}>
            <NavMenu />
          </NavMenuProvider>
          <div className="custom-scrollbar relative flex-1 overflow-y-auto">
            {isLoading ? <Loading /> : <ReportingState />}
          </div>
        </div>
      </div>
    );
  }

  return <Navigate to={generateAppRoute('NOT_FOUND')} />;
}
