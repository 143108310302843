import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { useKeyImagesQuery } from '@/api/queries';
import { useFormStructure } from '@/shared/guard';
import {
  useDefaultFindingStore,
  useFindingStore,
  useKeyImagesStore,
  useReportStore,
} from '@/store';

import { useUpdateKeyImagesStatus } from '../../hook';
import { useEvaluateLinks } from './use-evaluate-links.hook';
import { mapKeyImages } from './util';

/**
 * The `ReportingState` component is responsible for managing and initializing
 * the state related to reporting, including findings, key images, and organ links.
 * It utilizes various custom hooks, queries, and effects to fetch and update data
 * as needed.
 *
 * @remarks
 * This component integrates with multiple stores and queries to handle the reporting
 * state. It ensures that key images are fetched, mapped, and updated, and that the
 * organ state is initialized based on findings and default findings.
 */
export function ReportingState() {
  const { links } = useFormStructure();
  const evaluateLinks = useEvaluateLinks(links);

  const { findings } = useFindingStore();
  const { defaultFindings } = useDefaultFindingStore();
  const { report } = useReportStore();
  const { keyImageLabels, keyImages, setKeyImages, shouldRecurFetchKi } = useKeyImagesStore();

  const { data: keyImagesData, refetch: refetchKeyImages } = useKeyImagesQuery({
    allowRetries: !!shouldRecurFetchKi,
    enabled: true,
  });

  /** FUNCTIONS */
  const updateKeyImageStatus = useUpdateKeyImagesStatus();

  /** EFFECTS */
  useEffect(() => {
    if (keyImagesData) {
      const mappedKeyImages = mapKeyImages(keyImagesData);

      setKeyImages(mappedKeyImages);
    }
  }, [keyImagesData]);

  useEffect(() => {
    if (shouldRecurFetchKi) {
      refetchKeyImages({ throwOnError: true });
    }
  }, [shouldRecurFetchKi]);

  useEffect(() => {
    if (!keyImages.length) {
      return;
    }

    updateKeyImageStatus();
  }, [keyImageLabels, report?.excludeDicomImages, keyImages]);

  useEffect(() => {
    if (Object.keys(findings).length > 0 || links || Object.keys(defaultFindings).length > 0) {
      evaluateLinks();
    }
  }, [findings, links, defaultFindings]);

  return <Outlet />;
}
