import { generateAppRoute } from '@/shared/util';

export function generateReportingRoute(studyId: string, key: string) {
  switch (key) {
    case 'favorite':
      return generateAppRoute('FINAL_IMPRESSIONS', { params: { studyId } });
    case 'key_images':
      return generateAppRoute('KEY_IMAGES', { params: { studyId } });
    case 'preview':
      return generateAppRoute('PREVIEW', { params: { studyId } });
    case 'reason_for_scan':
      return generateAppRoute('REASON_FOR_SCAN', { params: { studyId } });
    case 'retrieve_key_images':
      return generateAppRoute('RETRIEVE_KEY_IMAGES', { params: { studyId } });
    default:
      return generateAppRoute('ORGAN', { params: { organ: key.replace(/_/g, '-'), studyId } });
  }
}
