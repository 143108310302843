import { useState } from 'react';
import { Link } from 'react-router-dom';
import { cn, Icon, IconSource, Tooltip } from '@prenuvo/halo-foundation';
import { ChevronDown } from '@prenuvo/halo-icon';

import { useStudyGuard } from '@/shared/guard';
import { OrganLink, OrganLinkProgress } from '@/shared/model';
import { useOrganValidationStore } from '@/store';

import { usePreviewScrollPosition } from '../../../hook';
import { generateReportingRoute } from '../../../util';
import { getStatusContent } from './nav-menu.util';
import { useNavMenu } from './nav-menu-context';
import { ProgressIndicator } from './progress-indicator';

interface NavMenuLinkProps {
  index: number;
  isSubmenuItem: boolean;
  link: OrganLink;
  onClick?: () => void;
}

export function NavMenuLink({ index, isSubmenuItem = false, link, onClick }: NavMenuLinkProps) {
  const {
    currentOrgan,
    hoverTimeoutRef,
    isCollapsed,
    isSubmenuHovered,
    linkItemRefs,
    onHoveredMenuChange,
    onLinkSelect,
    onOrganSelect,
    selectedOrgan,
  } = useNavMenu();
  const { studyId } = useStudyGuard();

  const { getStatus } = useOrganValidationStore();
  const setPreviewScrollPosition = usePreviewScrollPosition();
  const [expandedMenus, setExpandedMenus] = useState<string[]>([]);

  const isActive = link.key === selectedOrgan || link.sub?.some((sub) => sub.key === selectedOrgan);
  const isDisabled = false;
  const hasSubmenu = link.sub && link.sub.length > 0;
  const isExpanded = expandedMenus.includes(link.key);

  const toggleSubmenu = (key: string) => {
    setExpandedMenus((prev) =>
      prev.includes(key) ? prev.filter((k) => k !== key) : [...prev, key],
    );
  };

  const hasSubmenuStatus = () =>
    link.sub?.some((subLink) => getStatus(subLink.key)?.error || getStatus(subLink.key)?.warning);

  const hasSubmenuError = () => link.sub?.some((subLink) => getStatus(subLink.key)?.error);

  const hasSubmenuWarning = () => link.sub?.some((subLink) => getStatus(subLink.key)?.warning);

  const shouldShowIndicator = (linkKey: string) => {
    const status = getStatus(linkKey);
    const isCurrentPage = currentOrgan && linkKey === currentOrgan;

    if (status?.warning || (hasSubmenu && hasSubmenuStatus())) {
      return true;
    }

    return !isCurrentPage && (status?.error || (hasSubmenu && hasSubmenuError()));
  };

  const getIndicatorProgress = (linkKey: string): null | OrganLinkProgress => {
    const status = getStatus(linkKey);
    const isCurrentPage = currentOrgan && linkKey === currentOrgan;

    if (!isCurrentPage && (status?.error || (hasSubmenu && hasSubmenuError()))) {
      return 'missingInput';
    }

    if (!status?.error && (status?.warning || (hasSubmenu && hasSubmenuWarning()))) {
      return 'oncorad';
    }

    return null;
  };

  return (
    <div
      className="relative"
      onMouseEnter={() => {
        clearTimeout(hoverTimeoutRef.current);
        onHoveredMenuChange(link.key);
      }}
      onMouseLeave={() => {
        hoverTimeoutRef.current = setTimeout(() => {
          if (!isSubmenuHovered) {
            onHoveredMenuChange(null);
          }
        }, 500);
      }}
    >
      <Link
        className={cn(
          'group px-2 inline-flex items-center justify-center whitespace-nowrap text-sm font-medium text-neutral-400 dark:text-neutral-400 hover:no-underline w-full',
          {
            'border-b border-dashed border-stone-700 py-2': link.key === 'reason_for_scan',
            'dark:bg-stone-800': isActive && isSubmenuItem,
            'pl-8 pr-2 hover:bg-stone-800 rounded-lg': isSubmenuItem,
            'text-neutral-800 dark:text-neutral-800': isDisabled || link.disabled,
          },
        )}
        data-testid="nav-menu-link"
        onClick={(e) => {
          if (hasSubmenu) {
            e.preventDefault();
            toggleSubmenu(link.key);
          } else {
            setPreviewScrollPosition();
            onOrganSelect(link.key);
            onLinkSelect?.(link.key);
            onClick?.();
          }
        }}
        to={generateReportingRoute(studyId, link.key)}
      >
        <Tooltip
          className={cn(
            'bg-stone-700 text-xs text-neutral-50 dark:bg-stone-700 dark:text-neutral-50',
            {
              hidden:
                (!isCollapsed && getStatusContent(getStatus(link.key)) === '' && !link.disabled) ||
                hasSubmenu,
            },
          )}
          content={`${link.title} ${link.disabled ? '- Not in SKU' : getStatusContent(getStatus(link.key))}`}
          side="right"
        >
          <div
            className={cn(
              'flex w-full justify-between static px-2 py-3 hover:bg-stone-800 dark:hover:bg-stone-800 rounded-lg h-10 items-center hover:text-neutral-400 dark:hover:text-neutral-400',
              {
                'bg-stone-800 dark:bg-stone-800 text-neutral-50 dark:text-neutral-50 hover:text-neutral-50 dark:hover:text-neutral-50':
                  isActive && (!hasSubmenu || !isExpanded || (isExpanded && isCollapsed)),
                'text-neutral-50 dark:text-neutral-50 hover:text-neutral-50 dark:hover:text-neutral-50':
                  isActive && hasSubmenu && isExpanded,
                'text-neutral-800 dark:text-neutral-800':
                  isSubmenuItem && link.disabled && !isActive,
                'w-auto relative px-3': isCollapsed,
              },
            )}
            data-selected={`${isActive}`}
            ref={(el: HTMLDivElement | null) => {
              linkItemRefs.current[index] = el;
            }}
          >
            {isCollapsed && link.icon && (
              <div>
                <Icon
                  aria-label={link.title}
                  className="fill-current"
                  size="md"
                  source={link.icon as IconSource}
                />
              </div>
            )}
            {!isCollapsed && (
              <span className="flex items-center">
                {link.icon && (
                  <Icon
                    aria-label={link.title}
                    className="mr-[12px] fill-current"
                    size="md"
                    source={link.icon as IconSource}
                  />
                )}
                <span className="max-w-[100px] whitespace-normal text-sm font-normal no-underline">
                  {link.title}
                </span>
              </span>
            )}
            <div className="flex items-center">
              {(!hasSubmenu || (hasSubmenu && !isExpanded) || (hasSubmenu && isCollapsed)) &&
                shouldShowIndicator(link.key) && (
                  <span className="ml-auto flex shrink-0 items-center">
                    <ProgressIndicator
                      isCollapsed={isCollapsed}
                      progress={getIndicatorProgress(link.key)}
                    />
                  </span>
                )}
              {hasSubmenu && !isCollapsed && (
                <Icon
                  className={cn(
                    'h-4 w-4 transition-transform fill-current ml-2',
                    isExpanded && 'rotate-180',
                  )}
                  source={ChevronDown}
                />
              )}
            </div>
          </div>
        </Tooltip>
      </Link>
      {hasSubmenu && isExpanded && !isCollapsed && (
        <div className="ml-2">
          {link.sub?.map((subLink, subIndex) => (
            <NavMenuLink
              key={subLink.key}
              index={index + subIndex}
              isSubmenuItem
              link={{
                key: subLink.key,
                disabled: subLink.disabled,
                progress: null,
                title: subLink.value,
              }}
            />
          ))}
        </div>
      )}
    </div>
  );
}
