/**
 * Removes empty parameters from the given object.
 *
 * This function filters out properties from the input object where the value is `null`, `undefined`, or an empty string.
 * However, it retains properties where the value is a number or a boolean, even if they are falsy (e.g., `0` or `false`).
 *
 * @param params - The object containing parameters to be filtered.
 * @returns A new object with empty parameters removed.
 */
export function removeEmptyParams<T>(params: T): Record<string, unknown> {
  return Object.fromEntries(
    Object.entries(params as Record<string, unknown>).filter(([, value]) => {
      const isOmittedValue = typeof value === 'number' || typeof value === 'boolean';
      const isValue = value !== null && value !== undefined && value !== '';

      return isOmittedValue ? true : isValue;
    }),
  );
}
