import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { ENDPOINTS } from '@/api/endpoints';
import { fetcher } from '@/api/fetcher';
import {
  RadiologistScanDetailError,
  RadiologistScanDetailRequest,
  RadiologistScanDetailResponse,
  StudiesError,
  StudiesRequest,
  StudiesResponse,
  StudyError,
  StudyRequest,
  StudyResponse,
} from '@/api/model';
import { removeEmptyParams } from '@/api/utils';

const KEY = {
  GET_RADIOLOGIST_SCAN_DETAILS: 'GET_RADIOLOGIST_SCAN_DETAILS',
  GET_STUDIES: 'GET_STUDIES',
  GET_STUDIES_BY_ID: 'GET_STUDIES_BY_ID',
} as const;

const request = {
  getRadiologistScanDetails: async (
    { payload: { studyId } }: RadiologistScanDetailRequest,
    signal?: AbortSignal,
  ) => {
    const { data } = await fetcher.APPS.get<RadiologistScanDetailResponse>(
      `${ENDPOINTS.APPS.STUDY}${studyId}/radiologistScanDetails`,
      { signal },
    );

    return data;
  },
  getStudies: async ({ payload: { params } }: StudiesRequest, signal?: AbortSignal) => {
    const { data } = await fetcher.APPS.get<StudiesResponse>(ENDPOINTS.APPS.STUDY, {
      params: removeEmptyParams(params),
      signal,
    });

    return data;
  },
  getStudy: async ({ payload: { studyId } }: StudyRequest, signal?: AbortSignal) => {
    const { data } = await fetcher.APPS.get<StudyResponse>(`${ENDPOINTS.APPS.STUDY}/${studyId}`, {
      signal,
    });

    return data;
  },
};

const query = {
  useGetRadiologistScanDetails: (
    { payload }: RadiologistScanDetailRequest,
    config?: UseQueryOptions<RadiologistScanDetailResponse, RadiologistScanDetailError>,
  ) =>
    useQuery<RadiologistScanDetailResponse, RadiologistScanDetailError>({
      queryFn: ({ signal }) => request.getRadiologistScanDetails({ payload }, signal),
      queryKey: [KEY.GET_RADIOLOGIST_SCAN_DETAILS, payload.studyId],
      ...config,
    }),
  useGetStudies: (
    { payload }: StudiesRequest,
    config?: UseQueryOptions<StudiesResponse, StudiesError>,
  ) =>
    useQuery<StudiesResponse, StudiesError>({
      queryFn: ({ signal }) => request.getStudies({ payload }, signal),
      queryKey: [KEY.GET_STUDIES, payload.params.users.map((user) => user), payload.params.status],
      ...config,
    }),
  useGetStudy: ({ payload }: StudyRequest, config?: UseQueryOptions<StudyResponse, StudyError>) =>
    useQuery<StudyResponse, StudyError>({
      queryFn: ({ signal }) => request.getStudy({ payload }, signal),
      queryKey: [KEY.GET_STUDIES_BY_ID, payload.studyId],
      ...config,
    }),
};

export const study = {
  KEY,
  query,
  request,
};
