import { create } from 'zustand';

import { Study } from '@/api';

type PatientStudiesState = {
  getLastRecentStudy: (studyID: string, scanDate: number) => null | Study;
  patientStudies: null | Study[];
  setPatientStudies: (studies: Study[]) => void;
};

export const usePatientStudiesStore = create<PatientStudiesState>((set, get) => ({
  getLastRecentStudy: (studyID: string, scanDate: number) => {
    const { patientStudies } = get();

    if (!patientStudies?.length) {
      return null;
    }

    const previousStudies = patientStudies.filter(
      (study) => study.studyID !== studyID && study.booking[0].utcStart < scanDate,
    );

    return [...previousStudies].sort((a, b) => b.booking[0].utcStart - a.booking[0].utcStart)[0];
  },
  patientStudies: null,
  setPatientStudies: (studies) => set({ patientStudies: studies }),
}));
