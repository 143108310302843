import { generatePath } from 'react-router-dom';

import { APP_ROUTES, AppRouteKey, DEFAULT_PATH_PARAMS, PathConfig, ROUTES } from '../model';

function getRouteParent(route: AppRouteKey) {
  return ROUTES[route].parent;
}

/**
 * Generates a route link based on the provided route key and optional configuration.
 *
 * @param route - The key of the route to generate the link for. Must be a valid `AppRouteKey`.
 * @param config - Optional configuration for the route link.
 *   - `params`: An object containing path parameters to replace in the route. If a parameter's value is `undefined`, it will be replaced with `null`.
 *   - `query`: A string representing the query parameters to append to the route.
 * @returns The generated route link as a string. If `config` is provided, the link will include the specified path parameters and query string.
 */
export function generateAppRoute(route: AppRouteKey, config?: PathConfig) {
  const parentRoute = getRouteParent(route);

  const parentPath = parentRoute ? APP_ROUTES[parentRoute] : undefined;
  const routePath = APP_ROUTES[route];

  const path = parentPath ? `${parentPath}/${routePath}` : routePath;

  if (config) {
    const { params, query } = config;

    const pathWithParams = generatePath(path, {
      ...DEFAULT_PATH_PARAMS,
      ...(params
        ? Object.entries(params).reduce(
            (acc, [key, value]) => ({
              ...acc,
              [key]: value === undefined ? null : value,
            }),
            {},
          )
        : {}),
    });

    return query ? `${pathWithParams}?${query}` : pathWithParams;
  }

  return path;
}
