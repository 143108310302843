type MonthFormat = '2-digit' | 'long' | 'narrow' | 'numeric' | 'short';

/**
 * Adjusts a UTC timestamp or ISO date string to local time by adding the timezone offset
 * @param utcTimestamp UTC timestamp in seconds or ISO 8601 date string
 * @returns Date object adjusted for local timezone
 */
export function adjustTimestampToLocal(utcTimestamp: number | string): Date {
  const timestamp =
    typeof utcTimestamp === 'number' ? utcTimestamp * 1000 : new Date(utcTimestamp).getTime();

  const date = new Date(timestamp);
  const timezoneOffset = date.getTimezoneOffset() * 60000;

  if (typeof utcTimestamp === 'number') {
    return new Date(timestamp - timezoneOffset);
  }

  return new Date(timestamp + timezoneOffset);
}

export function formatTimestamp(
  timestamp: number | string,
  use24HourFormat: boolean = false,
): { ampm: string; date: Date; hours: number; minutes: string } {
  const date = new Date(timestamp);
  const getHours = date.getHours();
  const getMinutes = date.getMinutes();
  const formattedMinutes = getMinutes < 10 ? `0${getMinutes}` : `${getMinutes}`;
  const ampm = getHours >= 12 ? 'PM' : 'AM';
  const formattedHours = getHours % 12 || 12;

  return {
    ampm: use24HourFormat ? '' : ampm,
    date,
    hours: use24HourFormat ? getHours : formattedHours,
    minutes: formattedMinutes,
  };
}

export const formatMonthDayYear = (
  input: number | string,
  monthFormat: MonthFormat = 'short',
): string => {
  if (input === 0 || !input) {
    return 'Unknown Date';
  }

  let date: Date;

  if (typeof input === 'number') {
    date = new Date(input < 10000000000 ? input * 1000 : input);
  } else {
    try {
      let adjustedDate: Date;

      if (input.match(/^\d{4}-\d{2}-\d{2}$/)) {
        const [year, month, day] = input.split('-').map(Number);

        adjustedDate = new Date(Date.UTC(year, month - 1, day, 12, 0, 0));
      } else {
        adjustedDate = new Date(input);
      }

      const timezoneOffset = adjustedDate.getTimezoneOffset() * 60000;

      date = new Date(adjustedDate.getTime() + timezoneOffset);
    } catch (e) {
      return 'Unknown Date';
    }
  }

  if (Number.isNaN(date.getTime())) {
    return 'Unknown Date';
  }

  const month = date.toLocaleString('en-US', { month: monthFormat, timeZone: 'UTC' });
  const day = String(date.getDate());
  const year = String(date.getFullYear());

  return `${month} ${day}, ${year}`;
};
