import { Link } from 'react-router-dom';
import { cn, Tooltip } from '@prenuvo/halo-foundation';

import { useFormStructure, useStudyGuard } from '@/shared/guard';
import { OrganLink, OrganLinkProgress } from '@/shared/model';
import { useOrganValidationStore } from '@/store';

import { usePreviewScrollPosition } from '../../../hook';
import { generateReportingRoute } from '../../../util';
import { getStatusContent } from './nav-menu.util';
import { useNavMenu } from './nav-menu-context';
import { ProgressIndicator } from './progress-indicator';

interface SubMenuPopoverProps {
  subMenuLink: OrganLink;
}

export function SubMenuPopover({ subMenuLink }: SubMenuPopoverProps) {
  const { links } = useFormStructure();
  const { studyId } = useStudyGuard();

  const {
    currentOrgan,
    hoverTimeoutRef,
    isCollapsed,
    linkItemRefs,
    onHoveredMenuChange,
    onLinkSelect,
    onOrganSelect,
    onSubmenuHoverChange,
    selectedOrgan,
  } = useNavMenu();

  const { getStatus } = useOrganValidationStore();
  const setPreviewScrollPosition = usePreviewScrollPosition();

  const item = linkItemRefs.current[links.indexOf(subMenuLink)];
  const topOffset = item?.getBoundingClientRect().top || 0;
  const parentRect = item?.closest('[data-testid="nav-menu"]')?.getBoundingClientRect();
  const relativeTop = parentRect ? topOffset - parentRect.top : 0;

  const shouldShowSubmenuIndicator = (subLinkKey: string) => {
    const status = getStatus(subLinkKey);
    const isCurrentPage = currentOrgan && subLinkKey === currentOrgan;

    if (status?.warning) {
      return true;
    }

    return !isCurrentPage && status?.error;
  };

  const getSubmenuIndicatorProgress = (subLinkKey: string): null | OrganLinkProgress => {
    const status = getStatus(subLinkKey);
    const isCurrentPage = currentOrgan && subLinkKey === currentOrgan;

    if (!isCurrentPage && status?.error) {
      return 'missingInput';
    }

    if (!status?.error && status?.warning) {
      return 'oncorad';
    }

    return null;
  };

  return (
    <div
      key={`submenu-${subMenuLink.key}`}
      className="absolute left-full top-0 z-50 ml-2 min-w-48 rounded-lg bg-stone-900 shadow-lg"
      onMouseEnter={() => {
        clearTimeout(hoverTimeoutRef.current);
        onSubmenuHoverChange(true);
      }}
      onMouseLeave={() => {
        onSubmenuHoverChange(false);
        onHoveredMenuChange(null);
      }}
      style={{ top: `${relativeTop}px` }}
    >
      {(subMenuLink.sub ?? []).map(({ key, value }) => (
        <Link
          key={`submenu-${key}`}
          className="text-md group inline-flex w-full items-center whitespace-nowrap text-neutral-400 hover:no-underline dark:text-neutral-400"
          data-testid="nav-menu-link"
          onClick={() => {
            setPreviewScrollPosition();
            onOrganSelect(key);
            onLinkSelect?.(key);
          }}
          to={generateReportingRoute(studyId, key)}
        >
          <Tooltip
            className={cn(
              'bg-stone-700 text-xs text-neutral-50 dark:bg-stone-700 dark:text-neutral-50',
              {
                hidden: isCollapsed && getStatusContent(getStatus(key)) === '',
              },
            )}
            content={`${value} ${getStatusContent(getStatus(key))}`}
            side="right"
          >
            <div
              className={cn(
                'flex w-full static p-2 hover:bg-stone-800 dark:hover:bg-stone-800 rounded-lg h-10 items-center hover:text-neutral-400 dark:hover:text-neutral-400',
                {
                  'bg-stone-800 dark:bg-stone-800 text-neutral-50 dark:text-neutral-50 hover:text-neutral-50 dark:hover:text-neutral-50':
                    key === selectedOrgan,
                },
              )}
              data-selected={`${key === selectedOrgan}`}
            >
              {value}
              {shouldShowSubmenuIndicator(key) && (
                <span className="ml-auto flex items-center">
                  <ProgressIndicator
                    isCollapsed={false}
                    progress={getSubmenuIndicatorProgress(key)}
                  />
                </span>
              )}
            </div>
          </Tooltip>
        </Link>
      ))}
    </div>
  );
}
