import { useEffect, useState } from 'react';

import { Completed, Error, InProgress, MissingInput, ONCORAD } from '@/assets/icons';
import { OrganLinkProgress } from '@/shared/model';

type ProgressIndicatorProps = {
  isCollapsed: boolean;
  progress: null | OrganLinkProgress;
};

export function ProgressIndicator({ isCollapsed, progress }: ProgressIndicatorProps) {
  const [imageUrl, setImageUrl] = useState('');
  const [statusColor, setStatusColor] = useState('');

  useEffect(() => {
    switch (progress) {
      case 'Completed':
        setImageUrl(Completed);
        setStatusColor('bg-green-500');
        break;
      case 'Error':
        setImageUrl(Error);
        setStatusColor('bg-red-500');
        break;
      case 'InProgress':
        setImageUrl(InProgress);
        setStatusColor('bg-yellow-500');
        break;
      case 'missingInput':
        setImageUrl(MissingInput);
        break;
      case 'oncorad':
        setImageUrl(ONCORAD);
        break;
      default:
        setImageUrl('');
        setStatusColor('');
    }
  }, [progress]);

  if (!progress) {
    return null;
  }

  if (isCollapsed && ['missingInput', 'oncorad'].includes(progress)) {
    return (
      <span className="absolute right-1 top-1 size-4">
        <img alt={progress} src={imageUrl} />
      </span>
    );
  }

  if (isCollapsed) {
    return <span className={`absolute right-0 top-0 size-2 rounded-full ${statusColor}`} />;
  }

  return <img alt={progress} src={imageUrl} />;
}
