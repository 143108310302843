export const MOTION_CONTAINER_VARIANTS = {
  close: {
    transition: {
      damping: 15,
      duration: 0.2,
      type: 'spring',
    },
    width: '80px',
  },
  open: {
    transition: {
      damping: 20,
      duration: 0.4,
      type: 'spring',
    },
    width: '202px',
  },
};
