import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';

import { API_ENDPOINTS, makeApiRequest, RenderedSummary, Report } from '@/api';
import { ML_FINDINGS } from '@/api/mocks/constants';
import { useUser } from '@/core/user';
import { useFormStructure, useStudyGuard } from '@/shared/guard';
import { categorizeFindings, groupFindingsByOrgan, IS_LOCAL_ENV } from '@/shared/util';
import {
  useAIDefaultValuesStore,
  useDefaultFindingStore,
  useFindingStore,
  useReportStore,
} from '@/store';

import { extractMlServerFindings } from './useReportQuery.util';

export const useReportQuery = () => {
  const {
    patient: { gender },
    radiologistScan: { skuID },
    studyId,
  } = useStudyGuard();
  const { user } = useUser();
  const { uuid } = useFormStructure();

  const { findings, setFindings } = useFindingStore();
  const { defaultFindings, setDefaultFindings } = useDefaultFindingStore();
  const { setIsReportLoading, setReport } = useReportStore();
  const [reportData, setReportData] = useState<Report | undefined>();
  const { setAIFindings } = useAIDefaultValuesStore();

  const fetchReport = () =>
    makeApiRequest<Report>(
      'FCT',
      `${API_ENDPOINTS.report.getReport}/${studyId}?user_id=${user.user_id}&study_sku=${skuID}&patient_gender=${gender}`,
      'GET',
    );

  const fetchRenderedSummary = (id: string) =>
    makeApiRequest<RenderedSummary[]>(
      'FCT',
      `${API_ENDPOINTS.finding.renderedSummary}report/${id}`,
      'GET',
    );

  const { data: report, isLoading: isReportLoading } = useQuery({
    enabled: !!studyId && !!uuid && !!skuID && !!user,
    queryFn: fetchReport,
    queryKey: ['report', studyId, user.user_id, uuid],
  });

  const { data: renderedSummary, isLoading: isSummaryLoading } = useQuery({
    enabled: !!report?.data?.uuid,
    queryFn: () => fetchRenderedSummary(report!.data.uuid),
    queryKey: ['renderedSummary', report?.data.uuid],
    staleTime: Infinity,
  });

  useEffect(() => {
    setIsReportLoading(isReportLoading || isSummaryLoading);
  }, [isReportLoading, isSummaryLoading, setIsReportLoading]);

  useEffect(() => {
    if (report) {
      setReport(report.data);
      setReportData(report.data);
    }
  }, [report]);

  useEffect(() => {
    if (renderedSummary) {
      const renderedSummaryData = IS_LOCAL_ENV
        ? [...(renderedSummary?.data || []), ...ML_FINDINGS]
        : renderedSummary?.data;

      const groupedData = groupFindingsByOrgan(renderedSummaryData as RenderedSummary[]);

      const mlServerFindings = extractMlServerFindings(groupedData);

      setAIFindings(mlServerFindings);

      Object.keys(groupedData).forEach((organ) => {
        const { commonFindings, defaultFindings: defaultFindingsData } = categorizeFindings(
          groupedData[organ],
        );

        setFindings({
          ...findings,
          [organ]: commonFindings,
        });

        setDefaultFindings({
          ...defaultFindings,
          [organ]: defaultFindingsData,
        });
      });
    }
  }, [renderedSummary]);

  return { isLoading: isReportLoading || isSummaryLoading, report: reportData };
};
