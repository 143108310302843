import { ReactNode } from 'react';
import { Navigate, useParams } from 'react-router-dom';

import { api } from '@/api';
import { Loading } from '@/shared/ui';
import { generateAppRoute } from '@/shared/util';

import { StudyGuardProvider } from './study-guard.provider';

interface StudyGuardProps {
  children: ReactNode;
}

export function StudyGuard({ children }: StudyGuardProps) {
  const { studyId } = useParams<{ studyId: string }>();

  if (!studyId) {
    return <Navigate replace to={generateAppRoute('NOT_FOUND')} />;
  }

  const {
    data: radiologistScan,
    isError: isRadiologistScanError,
    isLoading: isRadiologistScanLoading,
  } = api.apps.study.query.useGetRadiologistScanDetails({
    payload: { studyId },
  });

  const {
    data: patients,
    isError: patientsError,
    isLoading: isPatientsLoading,
  } = api.pii.patient.query.useGetPatient({
    payload: { params: { user_id: radiologistScan?.patientID } },
  });

  if (isRadiologistScanLoading || isPatientsLoading) {
    return <Loading />;
  }

  if (isRadiologistScanError || patientsError) {
    return <Navigate replace to={generateAppRoute('NOT_FOUND')} />;
  }

  const patient = patients![0];

  return (
    <StudyGuardProvider patient={patient} radiologistScan={radiologistScan!} studyId={studyId}>
      {children}
    </StudyGuardProvider>
  );
}
