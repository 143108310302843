import { useMutation, useQuery, UseQueryOptions } from '@tanstack/react-query';

import { ENDPOINTS } from '@/api/endpoints';
import { fetcher } from '@/api/fetcher';
import {
  CreateRenderedSummaryError,
  CreateRenderedSummaryRequest,
  CreateRenderedSummaryResponse,
  PatchRenderedSummaryError,
  PatchRenderedSummaryRequest,
  PatchRenderedSummaryResponse,
  RenderedSummaryError,
  RenderedSummaryRequest,
  RenderedSummaryResponse,
} from '@/api/model';

const KEY = {
  GET_RENDERED_SUMMARY: 'GET_RENDERED_SUMMARY',
} as const;

const request = {
  getRenderedSummary: async (
    { payload: { uuid } }: RenderedSummaryRequest,
    signal?: AbortSignal,
  ) => {
    const { data } = await fetcher.FCT.get<RenderedSummaryResponse>(
      `${ENDPOINTS.FCT.RENDERED_SUMMARY}${uuid}`,
      { signal },
    );

    return data;
  },
  patchRenderedSummary: async ({ payload }: PatchRenderedSummaryRequest) => {
    const { data } = await fetcher.FCT.patch<PatchRenderedSummaryResponse>(
      `${ENDPOINTS.FCT.RENDERED_SUMMARY}${payload.uuid}`,
      payload,
    );

    return data;
  },
  postCreateRenderedSummary: async ({ payload }: CreateRenderedSummaryRequest) => {
    const { data } = await fetcher.FCT.post<CreateRenderedSummaryResponse>(
      ENDPOINTS.FCT.RENDERED_SUMMARY,
      payload,
    );

    return data;
  },
};

const query = {
  useGetRenderedSummary: (
    { payload }: RenderedSummaryRequest,
    config?: UseQueryOptions<RenderedSummaryResponse, RenderedSummaryError>,
  ) =>
    useQuery<RenderedSummaryResponse, RenderedSummaryError>({
      queryFn: ({ signal }) => request.getRenderedSummary({ payload }, signal),
      queryKey: [KEY.GET_RENDERED_SUMMARY, payload.uuid],
      ...config,
    }),
};

const mutation = {
  usePatchRenderedSummary: () =>
    useMutation<
      PatchRenderedSummaryResponse,
      PatchRenderedSummaryError,
      PatchRenderedSummaryRequest
    >({
      mutationFn: request.patchRenderedSummary,
    }),
  usePostCreateRenderedSummary: () =>
    useMutation<
      CreateRenderedSummaryResponse,
      CreateRenderedSummaryError,
      CreateRenderedSummaryRequest
    >({
      mutationFn: request.postCreateRenderedSummary,
    }),
};

export const renderedSummary = {
  KEY,
  mutation,
  query,
  request,
};
