import { useMutation, useQueryClient } from '@tanstack/react-query';

import { ENDPOINTS } from '@/api/endpoints';
import { fetcher } from '@/api/fetcher';
import {
  CreateReportFindingError,
  CreateReportFindingRequest,
  CreateReportFindingResponse,
  PatchReportFindingError,
  PatchReportFindingRequest,
  PatchReportFindingResponse,
} from '@/api/model';

const request = {
  patchReportFinding: async ({ payload }: PatchReportFindingRequest) => {
    const { data } = await fetcher.FCT.patch<PatchReportFindingResponse>(
      `${ENDPOINTS.FCT.REPORT_FINDING}${payload.uuid}`,
      payload,
    );

    return data;
  },
  postCreateReportFinding: async ({ payload }: CreateReportFindingRequest) => {
    const { data } = await fetcher.FCT.post<CreateReportFindingResponse>(
      ENDPOINTS.FCT.REPORT_FINDING,
      payload,
    );

    return data;
  },
};

const mutation = {
  usePatchReportFinding: () => {
    const queryClient = useQueryClient();

    return useMutation<
      PatchReportFindingResponse,
      PatchReportFindingError,
      PatchReportFindingRequest
    >({
      mutationFn: request.patchReportFinding,
      onSuccess: () => queryClient.invalidateQueries({ queryKey: ['report'] }),
    });
  },
  usePostCreateReportFinding: () =>
    useMutation<CreateReportFindingResponse, CreateReportFindingError, CreateReportFindingRequest>({
      mutationFn: request.postCreateReportFinding,
    }),
};

export const reportFinding = {
  mutation,
  request,
};
